/* eslint-disable no-restricted-syntax */
import React from 'react';
import { v4 as uuid } from 'uuid';
import SceneHelper from 'utils/SceneHelper';
import {
  shapeTypes,
  pageMods,
  layoutGutterTypes,
  weekStartingConst,
  modes,
  borderTypes,
  orientations,
} from 'constants/index';
import {
  min,
  findIndex,
  merge,
  find,
  cloneDeep,
  filter,
  flattenDeep,
  defaultsDeep,
  pick,
  max,
  get,
  remove,
  includes,
  isEqual,
} from 'lodash';
import { renderToString } from 'react-dom/server';
import CalculationShapeHelper from 'utils/CalculationShapeHelper';
import migrations from 'api/migrations';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import addDays from 'date-fns/addDays';
import getMonth from 'date-fns/getMonth';
import format from 'date-fns/format';
import { TextForeign, normalizeObject } from 'utils/utils';
import { fullScreenLayout } from 'api/layouts';
import { getCalendarSettings, getDefaultCalendarShapeSize } from 'constants/calendarSettings';
import DomHelper from 'utils/DomHelper';
import startOfWeek from 'date-fns/startOfWeek';
import { mmToInches } from 'utils/number';
import { EDGE_WRAP } from 'constants/editor';
import { initImage } from './utils'; // TODO: fix
import defaultTextProps from '../constants/defaultTextProps';

class StoreSceneHelper {
  constructor({ debug = false }) {
    this.debug = debug;
  }

  getDefaultPageConfig = ({ pageMode = pageMods.layout }) => ({
    pageMode, // it can be a layout or a design
    layoutId: pageMode === pageMods.layout ? 'default' : '', // current layout id
    layoutArea: { x: 0, y: 0, width: 1, height: 1 },
    designId: '', // current design id
    layoutGutterType: layoutGutterTypes.noGutter,
  });

  getDefaultCalendarPageConfig = () => ({
    pageMode: pageMods.design, // it can be a layout or a design
    layoutId: '', // current layout id
    designId: '', // current design id
    layoutGutterType: layoutGutterTypes.noGutter,
    layoutArea: { x: 0, y: 0, width: 1, height: 0.5 },
    disableLayouts: true,
  });

  getDefaultPage = ({
    id = uuid(),
    label,
    type,
    pageMode,
    options: { width = 210, height = 330 } = {},
    ...props
  } = {}) => {
    if (type === 'calendar-page') {
      const border = 10;
      return {
        id,
        label,
        ...props,
        shapes: [
          {
            id: uuid(),
            x: border,
            y: border,
            rotation: 0,
            width: width - 2 * border,
            height: height / 2 - border,
            type: shapeTypes.dropZone,
            image: null,
          },
        ],
        config: this.getDefaultCalendarPageConfig(),
      };
    }
    return {
      id,
      label,
      ...props,
      shapes: [],
      config: this.getDefaultPageConfig({ pageMode }),
    };
  };

  defaultImage = {
    x: 0,
    y: 0,
    width: 1,
    height: 1,
    brightness: 0,
    contrast: 0,
    saturation: 0,
  };

  /**
   * remove not valid shapes, double background
   * @param {[Shape]} shapes
   * @return {[Shape]} new shapes
   */
  // normalizeShapes = (shapes) => {
  //   const newShapes = shapes.filter(({ id }) => id);
  // }

  newText(scene, pageId) {
    const currentPageId = pageId || SceneHelper.currentPageId({ scene });
    const fill = SceneHelper.getCurrentThemTextColor({ scene }) || defaultTextProps.fill;
    const allowArea = SceneHelper.calcAllowAreaSync({ scene }, shapeTypes.text, currentPageId);
    const shapeWidth = (allowArea.width - allowArea.x) * 0.85;
    // TODO: Probably better to put it into the configuration of the editor (e.g. from initiate or default value based on type)
    const defaultFontSize = 8;
    const fontSize = Math.min(parseInt(shapeWidth / 12, 10), defaultFontSize);
    const { x, y } = SceneHelper.shapeToCenter({ width: shapeWidth, height: fontSize }, { scene }, pageId);
    const textShape = {
      ...defaultTextProps,
      id: uuid(),
      x,
      y,
      fill,
      width: shapeWidth,
      fontSize,
      type: shapeTypes.text,
    };
    return textShape;
  }

  newDropZone(scene, pageId) {
    const dimensions = SceneHelper.getDimensionsSync({ scene });
    const id = uuid();
    const shapeWidth = min([dimensions.width, dimensions.height]) * 0.5;
    const { x, y } = SceneHelper.shapeToCenter({ width: shapeWidth, height: shapeWidth }, { scene }, pageId);
    const dropZoneShape = {
      id,
      x,
      y,
      rotation: 0,
      width: shapeWidth,
      height: shapeWidth,
      type: shapeTypes.dropZone,
      image: null,
    };
    return dropZoneShape;
  }

  newClipart(scene, payload, pageId) {
    const { config } = scene;
    const { id: imageId, naturalHeight, naturalWidth } = payload;
    const id = uuid();
    const dimensions = SceneHelper.getDimensionsSync({ scene });
    const shapeWidth = min([dimensions.width, dimensions.height]) * 0.5;
    const image = {
      ...this.defaultImage,
      id: imageId,
      initialized: false,
      naturalHeight,
      naturalWidth,
      width: naturalWidth,
      height: naturalHeight,
      rotation: 0,
    };
    const shapeHeight = shapeWidth * (naturalHeight / naturalWidth);
    const { x, y } = SceneHelper.shapeToCenter({ width: shapeWidth, height: shapeHeight }, { scene }, pageId);
    const shape = initImage(
      { id, x, y, rotation: 0, width: shapeWidth, height: shapeHeight, type: shapeTypes.clipart, image },
      config,
    );
    return shape;
  }

  changeAllPages(scene, callback) {
    const pages = SceneHelper.getAllPages({ scene });
    const newScene = cloneDeep(scene);
    newScene.pages = pages.map((page) => callback(page, scene));
    return newScene;
  }

  /**
   * Callback for change shape.
   * @callback changeShapeCallback
   * @param {import('./scene.types').Shape} shape - shape object.
   * @param {import('./scene.types').ScenePage} page - page object.
   * @param {SceneState} scene - page object.
   */

  /**
   * Callback for change page.
   * @callback ChangePageCallback
   * @param {import('./scene.types').ScenePage} page - page object.
   * @param {SceneState} scene - page object.
   */

  /**
   * Change all page in page
   * @param {Object} page page object
   * @param {changeShapeCallback} callback callback return changed shape
   * @param {SceneState} scene scene
   * @returns {Object} modified page
   */
  changeAllShapesInPage(page, callback, scene) {
    const { shapes } = page;
    return { ...page, shapes: shapes.map((shape) => callback(shape, page, scene)) };
  }

  changeAllShapes(scene, callback) {
    return this.changeAllPages(scene, (page) => {
      return this.changeAllShapesInPage(page, callback, scene);
    });
  }

  /**
   * @param {SceneState} scene
   * @param {string} pageId
   * @param {ChangePageCallback} callback
   * @returns {SceneState}
   */
  changePageByPageId(scene, pageId, callback) {
    if (!pageId) return scene;
    return this.changeAllPages(scene, (page) => {
      if (page.id === pageId) {
        return callback(page, scene);
      }
      return page;
    });
  }

  /**
   * @param {SceneState} scene
   * @param {{ pageId: string }} param2
   * @returns {SceneState}
   */
  removePageById(scene, { pageId }) {
    const pages = SceneHelper.getAllPages({ scene });
    const newScene = cloneDeep(scene);
    newScene.pages = pages.filter((page) => page.id !== pageId);
    return newScene;
  }

  /**
   * @param {SceneState} scene
   * @param {{ id: string }} param2
   * @returns {SceneState}
   */
  deleteImage(scene, { id }) {
    const mode = SceneHelper.getMode({ scene });
    let newScene = cloneDeep(scene);
    if (mode === modes.photoPrint) {
      const pageIds = [];
      const pages = SceneHelper.getAllPages({ scene });
      pages.forEach((page) => {
        page?.shapes?.forEach((shape) => {
          if (shape?.image?.id === id) {
            pageIds.push(page.id);
          }
        });
      });
      pageIds.forEach((pageId) => {
        newScene = this.removePageById(newScene, { pageId });
      });
    } else {
      newScene = this.changeAllPages(scene, (page) => {
        const newShapes = page?.shapes
          ?.map((shape) => {
            if (shape.image?.id === id) {
              return null;
            }
            return shape;
          })
          .filter(Boolean);
        return { ...page, shapes: newShapes };
      });
    }
    return newScene;
  }

  /**
   * @param {SceneState} scene
   * @param {ChangePageCallback} callback
   * @returns {SceneState}
   */
  changeCurrentPage(scene, callback) {
    const currentPageId = SceneHelper.currentPageId({ scene });
    return this.changePageByPageId(scene, currentPageId, callback);
  }

  setCurrentPageConfig(scene, props) {
    return this.changeCurrentPage(scene, (page) => merge(page, { config: { ...props } }));
  }

  changeBackground(scene, payload) {
    const callbackChangeBackground = (page, scene) => {
      const { config } = scene;
      const newPage = page;
      const id = uuid();
      const { id: imageId, naturalHeight, naturalWidth } = payload;
      const image = {
        ...this.defaultImage,
        id: imageId,
        initialized: false,
        naturalHeight,
        naturalWidth,
        width: naturalWidth,
        height: naturalHeight,
      };
      const backgroundShape = initImage(
        { id, x: 0, y: 0, width: 1, height: 1, type: shapeTypes.background, image },
        config,
      );
      const backgroundIndex = findIndex(newPage.shapes, { type: shapeTypes.background });
      if (!(backgroundIndex + 1)) {
        newPage.shapes = [backgroundShape, ...newPage.shapes];
      } else {
        newPage.shapes[backgroundIndex] = backgroundShape;
      }
      return newPage;
    };
    const viewAllPages = SceneHelper.getViewAllPages({ scene });

    if (viewAllPages) {
      return this.changeAllPages(scene, callbackChangeBackground);
    }
    return this.changeCurrentPage(scene, callbackChangeBackground);
  }

  changeBackgroundColor(scene, { value, changeAll, changeOnAllPages } = {}) {
    const backgroundOptions = SceneHelper.getBackgroundOption({ scene });
    const newBackground = find(backgroundOptions, { value });
    const newScene = cloneDeep(scene);
    const viewAllPages = SceneHelper.getViewAllPages({ scene: newScene });

    if (changeOnAllPages || viewAllPages) {
      newScene.config.currentBackground = newBackground;
    }
    const callbackChangeBackgroundColor = (_page) => {
      let page = _page;
      const oldBackgroundColor = SceneHelper.getBackgroundColorByPageId({ scene }, page?.pageId);
      const newTextColor = newBackground?.textColor;
      const oldTextColor = find(backgroundOptions, { value: oldBackgroundColor })?.textColor;
      page = merge(page, { config: { backgroundColor: value } });
      if (newTextColor !== oldTextColor) {
        page = this.changeAllShapesInPage(
          page,
          (shape) => {
            if (SceneHelper.isText(shape) && (shape.fill === oldTextColor || changeAll)) {
              return { ...shape, fill: newTextColor };
            }
            if (SceneHelper.isCalendar(shape)) {
              const newShape = shape;
              if (shape.mainColor === oldTextColor || changeAll) {
                newShape.mainColor = newTextColor;
              }
              if (shape.headerColor === oldTextColor || changeAll) {
                newShape.headerColor = newTextColor;
              }
              return { ...shape, mainColor: newTextColor };
            }
            return shape;
          },
          scene,
        );
      }
      return page;
    };

    if (viewAllPages || changeOnAllPages) {
      return this.changeAllPages(newScene, callbackChangeBackgroundColor);
    }
    return this.changeCurrentPage(newScene, callbackChangeBackgroundColor);
  }

  unsetBackground(scene) {
    return this.changeCurrentPage(scene, (page) => {
      const newPage = page;
      newPage.shapes = filter(newPage.shapes, (shape) => shape.type !== shapeTypes.background);
      return newPage;
    });
  }

  unwrap(root, tagname, extra) {
    const elms = root.getElementsByTagName(tagname);
    const l = elms.length;
    let i;
    for (i = l - 1; i >= 0; i--) {
      while (elms[i].firstChild) elms[i].parentNode.insertBefore(elms[i].firstChild, elms[i]);
      if (extra) extra(elms[i]);
      elms[i].parentNode.removeChild(elms[i]);
    }
  }

  getTextArr(shape) {
    const tempDocument = document.getElementById('calculation-element');
    if (!tempDocument) return undefined;
    const id = uuid();
    const strTextElement = renderToString(
      <TextForeign object={shape} visibility="hidden" idDivContainer={id} defaultText />,
    );
    if (!strTextElement) return undefined;
    tempDocument.innerHTML = strTextElement;
    const container = document.getElementById(id);
    this.unwrap(container, 'span');
    const newContainer = document.getElementById(id);
    const textArr = flattenDeep(DomHelper.getTextLinesFromDomElement(newContainer));
    return textArr;
  }

  changeAllShapesInCalendarDays(scene, callback) {
    const newScene = this.changeAllShapes(scene, (shape, page) => {
      if (SceneHelper.isCalendar(shape)) {
        if (shape.days?.length) {
          const newShape = cloneDeep(shape);
          newShape.days = shape.days.map((day) => {
            if (day.shapes) {
              const newDay = day;
              newDay.shapes = newDay.shapes?.map((calendarShape) =>
                callback({ calendarShape, day, shape, page, scene }),
              );
              return newDay;
            }
            return day;
          });
          return newShape;
        }
      }
      return shape;
    });
    return newScene;
  }

  /**
   * @param {SceneState} scene
   * @returns {SceneState}
   */
  addTextArrToTextObjects(scene) {
    try {
      this.debug && console.group('addTextArrToTextObjects');
      const newScene = this.changeAllPages(scene, (page) => {
        const { shapes } = page;
        const newShapes = shapes.map((shape) => {
          if (SceneHelper.isText(shape) && !SceneHelper.isEmptyShape(shape)) {
            const textArr = this.getTextArr(shape);
            let newShape = shape;
            if (textArr?.length) {
              newShape = { ...shape, textArr };
            }
            const allowArea = SceneHelper.calcAllowAreaSync({ scene }, shapeTypes.text, page.id);
            const normalizeObjectFunction = normalizeObject(allowArea);
            return { ...newShape, ...normalizeObjectFunction(newShape) };
          }
          if (SceneHelper.isCalendar(shape)) {
            const calendarSettings = getCalendarSettings(shape);
            const newShapeCalendar = shape;
            const allowArea = {
              x: calendarSettings.DayShape.margin.left,
              y: calendarSettings.DayShape.margin.top + newShapeCalendar.dayFontSize,
              width:
                calendarSettings.DayShape.width -
                calendarSettings.DayShape.margin.left -
                calendarSettings.DayShape.margin.height,
              height:
                calendarSettings.DayShape.height -
                calendarSettings.DayShape.margin.top -
                calendarSettings.DayShape.margin.bottom,
            };
            const normalizeCalendarShapeFunction = normalizeObject(allowArea);

            if (newShapeCalendar.days?.length) {
              newShapeCalendar.days = newShapeCalendar.days.map(({ shapes: calendarShapes, ...dayProp }) => {
                const newDay = { ...dayProp };
                newDay.shapes = calendarShapes.map((shapeInCalendar) => {
                  if (SceneHelper.isText(shapeInCalendar)) {
                    let newShapeInCalendar = { ...shapeInCalendar, ...normalizeCalendarShapeFunction(shapeInCalendar) };
                    const textArr = this.getTextArr(newShapeInCalendar);
                    if (textArr?.length) {
                      newShapeInCalendar = { ...newShapeInCalendar, textArr };
                    }
                    return { ...newShapeInCalendar };
                  }
                  return shapeInCalendar;
                });
                return newDay;
              });
            }
            return newShapeCalendar;
          }
          return shape;
        });
        return { ...page, shapes: newShapes };
      });
      this.debug && console.groupEnd('addTextArrToTextObjects');
      return newScene;
    } catch (e) {
      console.log('StoreSceneHelper.addTextArrToTextObjects() error:', e);
      return scene;
    }
  }

  /**
   * @param {SceneState} scene
   * @param {Object} payload
   * @param {String} payload.isEditing - id editing shape
   * @returns {SceneState}
   */
  updateIsEditing(scene, payload) {
    let newStore = scene;
    const mode = SceneHelper.getMode({ scene });
    if (payload.isEditing) {
      const pages = SceneHelper.getAllPages({ scene: newStore });
      pages.forEach((page) => {
        if (find(page.shapes, { id: payload.isEditing })) {
          newStore = this.updatePageId(newStore, { id: page.id });
        }
      });
    } else {
      if (mode === modes.photoPrint) {
        return this.updateViewAllPages(scene, { viewAllPages: true });
      }
      newStore = this.addTextArrToTextObjects(scene);
    }
    newStore.config.isCrop = false;
    newStore.config.textEditing = false;
    newStore.config.isEditing = payload.isEditing;
    return newStore;
  }

  /**
   * @param {SceneState} scene
   * @returns {SceneState}
   */
  updateFit(scene) {
    return this.updateCurrentShape(scene, (shape, page) => {
      if (!shape?.image?.width || !shape?.image?.height) return shape;
      const dimensions = SceneHelper.getDimensionsSync({ scene }, page.id);
      // const aspectRatioDimensions = dimensions.width / dimensions.height;
      // const aspectRatio = shape.width / shape.height;
      const newShape = cloneDeep(shape);
      newShape.rotation = 0;
      newShape.x = dimensions.frame;
      newShape.y = dimensions.frame;
      newShape.width = dimensions.width;
      newShape.height = dimensions.height;

      return initImage(newShape, scene.config);
    });
  }

  /**
   * @param {SceneState} scene
   * @returns {SceneState}
   */
  resetCropping(scene) {
    return this.updateCurrentShape(scene, (shape) => {
      if (!shape?.image?.width || !shape?.image?.height) return shape;
      const aspectRatioImage = shape.image.width / shape.image.height;
      const aspectRatio = shape.width / shape.height;
      const newShape = shape;
      if (aspectRatio > aspectRatioImage) {
        newShape.width = (newShape.width / aspectRatio) * aspectRatioImage;
      } else {
        newShape.height = (newShape.height / (1 / aspectRatio)) * (1 / aspectRatioImage);
      }
      newShape.image.x = 0;
      newShape.image.y = 0;
      newShape.image.centerX = 0.5;
      newShape.image.centerY = 0.5;

      newShape.image.width = 100;
      newShape.image.height = (1 / aspectRatioImage) * 100;

      return newShape;
    });
  }

  /**
   * move a selected shape backward in the order of overlapping shapes on the canvas, so that it appears in back of other shapes
   * @param {SceneState} scene
   * @returns {SceneState}
   */
  bringBackward(scene) {
    const selectedShapeId = SceneHelper.getSelectedShapeId({ scene });
    return this.changeCurrentPage(scene, (page) => {
      const shapes = get(page, 'shapes') || [];
      if (!shapes?.length) return page;
      const indexSelectedShape = findIndex(shapes, { id: selectedShapeId });
      if (
        SceneHelper.isCalendar(shapes[indexSelectedShape]) ||
        SceneHelper.isBackground(shapes[indexSelectedShape]) ||
        indexSelectedShape === -1 ||
        indexSelectedShape - 1 < 0 ||
        SceneHelper.isBackground(shapes[indexSelectedShape - 1])
      ) {
        return page;
      }
      const newPage = page;
      newPage.shapes = CalculationShapeHelper.arrayMove(shapes, indexSelectedShape, indexSelectedShape - 1);
      return newPage;
    });
  }

  /**
   * move a selected shape forward in the order of overlapping shapes on the canvas, so that it appears in front of other shapes
   * @param {SceneState} scene
   * @returns {SceneState}
   */
  bringForward(scene) {
    const selectedShapeId = SceneHelper.getSelectedShapeId({ scene });
    return this.changeCurrentPage(scene, (page) => {
      const shapes = get(page, 'shapes') || [];
      if (!shapes?.length) return page;
      const indexSelectedShape = findIndex(shapes, { id: selectedShapeId });
      if (
        SceneHelper.isCalendar(shapes[indexSelectedShape]) ||
        SceneHelper.isBackground(shapes[indexSelectedShape]) ||
        indexSelectedShape === -1 ||
        indexSelectedShape + 1 === shapes.length ||
        SceneHelper.isCalendar(shapes[indexSelectedShape + 1])
      ) {
        return page;
      }
      const newPage = page;
      newPage.shapes = CalculationShapeHelper.arrayMove(shapes, indexSelectedShape, indexSelectedShape + 1);
      return newPage;
    });
  }

  /**
   * the function will move the selected shape to the top of the canvas, which means that it will be in front of all other shapes that overlap it
   * @param {SceneState} scene
   * @returns {SceneState}
   */
  bringToFront(scene) {
    const selectedShapeId = SceneHelper.getSelectedShapeId({ scene });
    return this.changeCurrentPage(scene, (page) => {
      const shapes = get(page, 'shapes') || [];
      if (!shapes?.length) return page;
      const indexSelectedShape = findIndex(shapes, { id: selectedShapeId });
      if (
        SceneHelper.isCalendar(shapes[indexSelectedShape]) ||
        SceneHelper.isBackground(shapes[indexSelectedShape]) ||
        indexSelectedShape === -1 ||
        indexSelectedShape + 1 === shapes.length
      ) {
        return page;
      }
      const newPage = page;
      newPage.shapes = CalculationShapeHelper.arrayMove(shapes, indexSelectedShape, shapes?.length - 1);
      newPage.shapes = SceneHelper.sortShapes(newPage.shapes);
      return newPage;
    });
  }

  /**
   * the function will move the selected shape to the back of the canvas
   * @param {SceneState} scene
   * @returns {SceneState}
   */
  bringToBack(scene) {
    const selectedShapeId = SceneHelper.getSelectedShapeId({ scene });
    return this.changeCurrentPage(scene, (page) => {
      const shapes = get(page, 'shapes') || [];
      if (!shapes?.length) return page;
      const indexSelectedShape = findIndex(shapes, { id: selectedShapeId });
      if (
        SceneHelper.isCalendar(shapes[indexSelectedShape]) ||
        SceneHelper.isBackground(shapes[indexSelectedShape]) ||
        indexSelectedShape === -1 ||
        indexSelectedShape === 0
      ) {
        return page;
      }
      const newPage = page;
      newPage.shapes = CalculationShapeHelper.arrayMove(shapes, indexSelectedShape, 0);
      newPage.shapes = SceneHelper.sortShapes(newPage.shapes);
      return newPage;
    });
  }

  addShapeToCurrentPage(scene, type, payload) {
    let newShapeId = '';
    const newStore = this.changeCurrentPage(scene, (page, scene) => {
      const newPage = page;
      let newShape = {};
      if (type === shapeTypes.text) {
        newShape = this.newText(scene, page.id);
      }
      if (type === shapeTypes.dropZone) {
        newShape = this.newDropZone(scene, page.id);
      }
      if (type === shapeTypes.clipart) {
        newShape = this.newClipart(scene, payload, page.id);
      }
      newShapeId = newShape.id;
      newPage.shapes = SceneHelper.sortShapes([...newPage.shapes, newShape].filter(({ id }) => id));
      return newPage;
    });
    if (newShapeId) {
      newStore.config.isEditing = newShapeId || false;
      newStore.config.isCrop = false;
      newStore.config.textEditing = false;
    }
    return newStore;
  }

  removeShapeFromCurrentPage(scene, payload) {
    const newStore = this.changeCurrentPage(scene, (page) => {
      const newPage = page;
      newPage.shapes = filter(newPage.shapes, ({ id }) => id !== payload.id);
      return newPage;
    });
    newStore.config.isEditing = false;
    newStore.config.isCrop = false;
    newStore.config.textEditing = false;
    return newStore;
  }

  removeEditedShapeFromCurrentPage(scene) {
    const selectedShapeId = SceneHelper.getSelectedShapeId({ scene });
    if (selectedShapeId) {
      return this.removeShapeFromCurrentPage(scene, { id: selectedShapeId });
    }
    return scene;
  }

  removeLayoutsFromCurrentPage(scene) {
    const newScene = this.changeCurrentPage(scene, (page) => {
      const newPage = page;
      newPage.config.pageMode = pageMods.design;
      newPage.config.layoutId = '';
      newPage.config.designId = '';
      return { ...newPage, shapes: SceneHelper.recalculateShapesOnPage({ scene }, page.id) };
    });
    return newScene;
  }

  pasteShapeFromCurrentPage(scene) {
    if (scene.clipboard?.id) {
      const newStore = this.changeCurrentPage(scene, (page) => {
        const newPage = page;
        newPage.shapes = [...newPage.shapes, { ...scene.clipboard, id: uuid() }];
        return newPage;
      });
      newStore.config.isEditing = false;
      newStore.config.isCrop = false;
      newStore.config.textEditing = false;
      return newStore;
    }
    return scene;
  }

  copyShapeFromCurrentPage(scene, payload) {
    const newStore = scene;
    this.changeCurrentPage(scene, (page) => {
      page.shapes.forEach((shape) => {
        if (shape.id === payload.id) {
          newStore.clipboard = cloneDeep(shape);
        }
      });
      return page;
    });
    return newStore;
  }

  updateViewAllPages(scene, { viewAllPages, sortPages }) {
    let newScene = scene;
    newScene.config.viewAllPages = viewAllPages;
    if (viewAllPages) {
      newScene.config.isEditing = false;
      newScene.config.isCrop = false;
      newScene.config.textEditing = false;
      newScene.config.sortPages = sortPages || false;
    }
    newScene = this.addTextArrToTextObjects(newScene);
    return newScene;
  }

  updatePageLabels(scene) {
    return this.changeAllPages(scene, (page) => {
      if (page.date) {
        return { ...page, label: CalculationShapeHelper.formatDate(page.date) };
      }
      return page;
    });
  }

  updateDayTexts(scene) {
    const newScene = this.changeAllShapes(scene, (shape, page) => {
      if (SceneHelper.isCalendar(shape)) {
        const calendarSettings = getCalendarSettings(shape);
        const newShape = shape;
        const { date } = page;
        const weekStarting = SceneHelper.getWeekStartingByPageId({ scene }, page?.id);
        const startDate = startOfWeek(new Date(date?.year, date?.month), {
          weekStartsOn: weekStartingConst.sunday === weekStarting ? 0 : 1,
        });
        const rangeDays = eachDayOfInterval({
          start: startDate,
          end: addDays(startDate, 6 * calendarSettings.daysOfWeek - 1),
        });
        newShape.days = [];
        if (!SceneHelper.getHideEventsInGrid({ scene }, page.id)) {
          rangeDays.forEach((day) => {
            const events = SceneHelper.getActiveEventListByDate(
              { scene },
              CalculationShapeHelper.dateToDayMonthObj(day),
            );
            if (events?.length) {
              const color = getMonth(day) === date?.month ? shape.mainColor : shape.secondaryColor;
              const eventsObject = {
                x: calendarSettings.DayShape.margin.left,
                y: calendarSettings.DayShape.margin.top + shape.dayFontSize,
                fontSize: shape.dayFontSize - 2,
                fontFamily: shape.fontFamily,
                height: 1,
                rotation: 0,
                text: events.map(({ event }) => `<div>${event}</div>`).join(''),
                textAlign: 'left',
                textArr: [],
                type: 'text',
                fill: color,
                width:
                  calendarSettings.DaysContainerShape.width / 7 -
                  calendarSettings.DayShape.margin.left -
                  calendarSettings.DayShape.margin.right,
              };
              newShape.days.push({ day: format(day, 'dd.MM.yyyy'), shapes: [eventsObject] });
            }
          });
        }
        return newShape;
      }
      return shape;
    });
    return newScene;
  }

  // added/update placeholders in render text
  updateRenderText(scene) {
    let newScene = this.changeAllShapes(scene, (shape, page) => {
      if (SceneHelper.isText(shape)) {
        const newShape = shape;
        newShape.renderText = CalculationShapeHelper.addPlaceholders(
          shape.text,
          SceneHelper.getPlaceholdersObject({ scene }, page.id),
        );
        return newShape;
      }
      return shape;
    });
    newScene = this.updateDayTexts(scene);
    return newScene;
  }

  /**
   * @param {SceneState} scene
   * @param {Object} payload
   * @returns {SceneState}
   */
  setDesign(scene, payload) {
    const newPayload = cloneDeep(payload);
    this.debug && console.group('setDesign');
    let newScene = scene;
    const mode = SceneHelper.getMode({ scene });
    if (!newPayload?.id) {
      this.debug && console.groupEnd('setDesign');
      if (mode === modes.calendar) {
        return this.setStartCalendar(scene, { reset: true });
      }
      return scene;
    }
    const admin = SceneHelper.getAdmin({ scene });
    if (mode === modes.calendar) {
      let [coverPage, calendarPage] = newPayload.pages;
      const rangeCalendarFrom = this.getRangeCalendarFrom(scene);
      let newPages = [coverPage];
      rangeCalendarFrom.forEach((date) => {
        const currentPage = merge(cloneDeep(calendarPage), {
          id: `${date.month}`,
          type: 'calendar-page',
          date,
          config: { designId: newPayload.id },
        });
        currentPage.shapes = currentPage.shapes.map((shape) => {
          const newShape = { ...shape, id: uuid() };
          return newShape;
        });
        newPages.push(currentPage);
      });
      if (!admin) {
        if (newPayload.currentBackground?.value) {
          coverPage = defaultsDeep(coverPage, { config: { backgroundColor: newPayload.currentBackground.value } });
          calendarPage = defaultsDeep(calendarPage, {
            config: { backgroundColor: newPayload.currentBackground.value },
          });
        }
        const oldPages = newScene.pages;
        // remove images
        newPages = newPages.map((page) => {
          const pageImages = (
            find(oldPages, { id: `${page.id}` })?.shapes?.map((shape) => {
              if (SceneHelper.isDropZone(shape) || SceneHelper.isLayout(shape)) return shape.image;
              return null;
            }) || []
          ).filter(Boolean);
          page.shapes = page.shapes.map((shape) => {
            if (SceneHelper.isDropZone(shape) || SceneHelper.isLayout(shape)) {
              delete shape.image;
              shape = SceneHelper.transformShapeFromPercent({ shape, storeState: { scene }, pageId: page.id });
              shape.type = shapeTypes.dropZone;
              if (pageImages.length) shape.image = pageImages.pop();
            }
            return shape;
          });
          return page;
        });
        if (payload.forCurrentPage) {
          newScene = this.changeCurrentPage(newScene, (page) => find(newPages, { id: page.id }) || page);
        } else {
          newScene.pages = newPages;
        }
      }
      if (admin) {
        newScene.pages = newPages.map((page) => {
          page.shapes = page.shapes.map((shape) => {
            if (SceneHelper.isDropZone(shape) || SceneHelper.isLayout(shape)) {
              shape = SceneHelper.transformShapeFromPercent({ shape, storeState: { scene }, pageId: page.id });
              shape.type = shapeTypes.dropZone;
            }
            return shape;
          });
          return page;
        });
        const currentPageId = SceneHelper.currentPageId({ scene: newScene });
        if (!find(newPayload.pages, { id: currentPageId })) {
          newScene = this.updatePageId(newScene, newPayload.pages[0].id);
        }
      }
      newScene = this.setStartCalendar(newScene);
    } else {
      // change orientation
      newScene.config.orientation = newPayload.orientation || newScene.config.orientation;

      newScene = this.changeCurrentPage(newScene, (oldPage) => {
        const [newPage] = newPayload.pages;
        // remove images
        const pageImages = (
          oldPage.shapes?.map((shape) => {
            if (SceneHelper.isDropZone(shape) || SceneHelper.isLayout(shape)) return shape.image;
            return null;
          }) || []
        ).filter(Boolean);
        newPage.id = oldPage.id;
        if (!newPage.config) {
          newPage.config = oldPage.config;
        }
        newPage.shapes = newPage.shapes.map((shape) => {
          if (SceneHelper.isDropZone(shape) || SceneHelper.isLayout(shape)) {
            delete shape.image;
            shape = SceneHelper.transformShapeFromPercent({ shape, storeState: { scene }, pageId: oldPage.id });
            shape.type = shapeTypes.dropZone;
            if (pageImages.length) shape.image = pageImages.pop();
          }
          return initImage(shape, scene.config);
        });
        return newPage;
      });
    }
    newScene.config.designId = newPayload.id;
    newScene.config.productMode = 'designs';
    newScene.config.layoutId = '';
    if (mode !== modes.calendar || (!payload.forCurrentPage && !admin)) {
      newScene.config.currentPage = null;
    }
    newScene.config.isEditing = false;
    newScene.config.textEditing = false;
    newScene.config.isCrop = false;
    newScene = this.updateRenderText(newScene);
    newScene = this.addTextArrToTextObjects(newScene);
    this.debug && console.groupEnd('setDesign');
    return newScene;
  }

  /**
   * @param {SceneState} scene
   * @param {string} pageId
   * @param {changeShapeCallback} callback
   * @returns {SceneState}
   */
  changeAllShapesByPageId(scene, pageId, callback) {
    return this.changePageByPageId(scene, pageId, (page, scene) => {
      return this.changeAllShapesInPage(
        page,
        (shape, page, scene) => {
          return callback(shape, page, scene);
        },
        scene,
      );
    });
  }

  /**
   * @param {SceneState} scene
   * @param {changeShapeCallback} callback
   * @returns {SceneState}
   */
  changeAllShapesOnCurrentPage(scene, callback) {
    return this.changeCurrentPage(scene, (page, scene) => {
      return this.changeAllShapesInPage(
        page,
        (shape, page, scene) => {
          return callback(shape, page, scene);
        },
        scene,
      );
    });
  }

  /**
   * @param {SceneState} scene
   * @param {changeShapeCallback} callback
   * @param {string} shapeId
   * @returns {SceneState}
   */
  changeShapeOnPage(scene, callback, shapeId) {
    return this.changeAllShapesOnCurrentPage(scene, (shape, page, scene) => {
      if (shape.id === shapeId) {
        return callback(shape, page, scene);
      }
      return shape;
    });
  }

  addImagemagickToImages(scene) {
    return this.changeAllShapes(scene, (shape) => {
      if (SceneHelper.isDropZone(shape) && shape.image) {
        const image = pick(CalculationShapeHelper.transformImageFromPercent(shape), ['x', 'y', 'width', 'height']);
        const scale = shape.image.naturalWidth / image.width;
        const imagemagick = {
          cropX: -Math.round(image.x * scale),
          cropY: -Math.round(image.y * scale),
          cropWidth: Math.round(shape.width * scale),
          cropHeight: Math.round(shape.height * scale),
          outputWidth: Math.round(mmToInches(shape.width) * 300),
          outputHeight: Math.round(mmToInches(shape.height) * 300),
        };
        imagemagick.command = `-units PixelsPerInch -density 300 -crop ${imagemagick.cropWidth}x${imagemagick.cropHeight}+${imagemagick.cropX}+${imagemagick.cropY} -resize ${imagemagick.outputWidth}x${imagemagick.outputHeight}`;
        shape.image.imagemagick = imagemagick;
      }
      return shape;
    });
  }

  /**
   * please do not use the method for replace image
   * @param {import('./scene.types').SceneState} scene
   * @param {*} payload
   * @returns {import('./scene.types').SceneState}
   */
  updateShape(scene, payload) {
    let isCalendar = false;
    let isPositionChanged = false;
    let newScene = this.changeCurrentPage(scene, (page, scene) => {
      return this.changeAllShapesInPage(
        page,
        (shape, page, scene) => {
          if (shape.id === payload.id) {
            let newObject = merge({}, shape, { ...payload.modifier });
            if (SceneHelper.isCalendar(shape)) {
              if (payload.modifier.calendarType) {
                const dimensions = SceneHelper.getDimensionsSync({ scene }, page.id);
                const halfDimensionsHeight = dimensions.height / 2;
                const shapeWidth = min([dimensions.width, halfDimensionsHeight * (3 / 2)]) * 0.9;
                const calendarSize = getDefaultCalendarShapeSize(payload.modifier.calendarType);
                const calendarRatio = calendarSize.height / calendarSize.width;
                const shapeHeight = shapeWidth * calendarRatio;
                const { x, y } = CalculationShapeHelper.shapeToCenter(
                  { width: shapeWidth, height: shapeHeight },
                  { x: 0, y: halfDimensionsHeight, width: dimensions.width, height: halfDimensionsHeight, rotation: 0 },
                );
                newObject = merge(newObject, { x, y, width: shapeWidth, height: shapeHeight });
              }
              isCalendar = true;
              let calendarShapeSize = newObject?.calendarShapeSize;
              if (!calendarShapeSize) {
                calendarShapeSize = merge(
                  shape?.calendarShapeSize || {},
                  getDefaultCalendarShapeSize(newObject.calendarType),
                );
              }
              calendarShapeSize.height = calendarShapeSize.width * (newObject.height / newObject.width);
              newObject = merge(newObject, { calendarShapeSize });
            }
            const oldRatio = shape.width / shape.height;
            const newRatio = newObject.width / newObject.height;
            if (!isEqual(pick(newObject, ['x', 'y', 'width', 'height']), pick(shape, ['x', 'y', 'width', 'height']))) {
              isPositionChanged = true;
            }
            if (
              newObject.image &&
              (Math.abs(oldRatio - newRatio) > 0.0000005 || newObject.image.initialized === false)
            ) {
              // if changed ratio recalculate image
              return initImage(newObject, scene.config);
            }
            return newObject;
          }
          return shape;
        },
        scene,
      );
    });
    if (isCalendar) {
      newScene = this.changeAllShapesInCalendarDays(newScene, ({ calendarShape, shape }) => {
        const newShape = { ...calendarShape, ...shape?.eventStyle };
        return newShape;
      });
      newScene = this.addTextArrToTextObjects(newScene);
    }
    if (isPositionChanged) {
      newScene = this.setIsLayoutChanged(newScene);
    }
    return newScene;
  }

  /**
   * @param {import('./scene.types').SceneState} scene
   * @param {boolean} value
   * @returns {import('./scene.types').SceneState}
   */
  setIsLayoutChanged(scene, value = true) {
    const newScene = cloneDeep(scene);
    newScene.config.isLayoutChanged = value;
    return newScene;
  }

  /**
   * @param {SceneState} scene
   * @param {changeShapeCallback} callback
   * @returns {SceneState}
   */
  updateCurrentShape(scene, callback) {
    const selectedShapeId = scene.config.isEditing;
    return this.changeShapeOnPage(scene, callback, selectedShapeId);
  }

  updateLayoutArea(scene, payload) {
    let newScene = this.changeCurrentPage(scene, (page) => {
      const dimensions = SceneHelper.getDimensionsSync({ scene }, page.id);
      const newPage = cloneDeep(page);
      newPage.config.layoutArea = merge(
        {},
        newPage.config.layoutArea,
        CalculationShapeHelper.transformShapeToPercent({ ...payload.modifier }, dimensions),
      );
      return newPage;
    });
    newScene = this.changeCurrentPage(newScene, (page) => ({
      ...page,
      shapes: SceneHelper.recalculateShapesOnPage({ scene: newScene }, page.id),
    }));
    return newScene;
  }

  setImage(scene, { image, id }) {
    const blackAndWhite = !!scene.config.blackAndWhite;
    let newScene = this.changeCurrentPage(scene, (page, scene) => {
      const newPage = this.changeAllShapesInPage(
        page,
        (shape) => {
          if (shape.id === id) {
            const { id: imageId, naturalHeight, naturalWidth } = image;
            const newImage = {
              ...this.defaultImage,
              saturation: blackAndWhite ? -100 : 0,
              initialized: false,
              id: imageId,
              naturalHeight,
              naturalWidth,
              width: naturalWidth,
              height: naturalHeight,
            };
            return initImage({ ...shape, image: newImage }, scene.config);
          }
          return shape;
        },
        scene,
      );
      return newPage;
    });
    const mode = SceneHelper.getMode({ scene });
    if (mode !== modes.photoPrint && mode !== modes.photoBook) {
      newScene = this.updateIsEditing(newScene, { isEditing: id });
    }
    return newScene;
  }

  /** updated selectedPage */
  updatePageId(scene, payload, { updateViewAllPages = true } = {}) {
    const newPageId = `${payload.id}`;
    const currentPageId = SceneHelper.currentPageId({ scene });
    if (newPageId === currentPageId && !updateViewAllPages) return scene;
    const { pages } = scene;
    const mode = SceneHelper.getMode({ scene });
    let newScene = scene;
    newScene.config.isEditing = false;
    newScene.config.textEditing = false;
    newScene.config.isCrop = false;
    if (find(pages, { id: newPageId })) {
      newScene.config.currentPage = newPageId;
      newScene = updateViewAllPages ? this.updateViewAllPages(newScene, { viewAllPages: false }) : newScene;
      if (mode === modes.photoPrint) {
        const shapesFromCurrentPage = SceneHelper.getCurrentPageShapes({ scene });
        const textShapesFromCurrentPage = shapesFromCurrentPage?.filter((shape) => SceneHelper.isText(shape));
        if (textShapesFromCurrentPage.length) {
          newScene.config.isEditing = textShapesFromCurrentPage[0].id;
        } else {
          newScene.config.isEditing = shapesFromCurrentPage?.[0]?.id;
        }
      }
      return newScene;
    }
    newScene.config.currentPage = null;
    return newScene;
  }

  /**
   * @param {import('./scene.types').ScenePage} page
   * @param {SceneState} scene
   * @param {Boolean} [removeOld]
   * @param {Boolean} [withFrame]
   * @returns {import('./scene.types').ScenePage}
   */
  recalculateLayoutInPage(page, scene, removeOld = false, withFrame) {
    const newPage = page;
    const defaultLayout = SceneHelper.getDefaultLayout({ scene });
    const shapeFromOldLayout = newPage.shapes.filter((shape) => !!shape.originalAperture);
    if (withFrame && (newPage.config.layoutId !== defaultLayout.id || !shapeFromOldLayout.length)) {
      newPage.config.layoutId = defaultLayout.id;
      newPage.config.designId = '';
      newPage.config.pageMode = pageMods.layout;
    }
    const layout = SceneHelper.getLayoutApertures({ scene }, page.id);
    const allowArea = SceneHelper.getAllowArea({ scene }, page.id, withFrame);
    const doNotEditableLayout = SceneHelper.getDeNotEditableLayout({ scene });
    let newLayout = layout.map((aperture) => {
      const type = aperture.type === shapeTypes.layout && !doNotEditableLayout ? shapeTypes.dropZone : aperture.type;

      return {
        ...(type !== shapeTypes.layout
          ? SceneHelper.transformShapeFromPercent({
              shape: aperture,
              storeState: { scene },
              pageId: page.id,
              customArea: allowArea,
            })
          : aperture),
        type,
      };
    });
    if (removeOld) {
      const imagesFromOldLayout = shapeFromOldLayout.map((shape) => shape.image).filter(Boolean);
      newLayout = newLayout.map((apertureShape) => {
        if (
          imagesFromOldLayout.length &&
          (apertureShape.type === shapeTypes.dropZone || apertureShape.type === shapeTypes.layout)
        ) {
          return initImage({ ...apertureShape, image: imagesFromOldLayout.shift() }, scene.config);
        }
        return apertureShape;
      });
      const mode = SceneHelper.getMode({ scene });
      newPage.shapes = newPage.shapes
        .map((shape) => {
          if (!shape.originalAperture) {
            if (shape.type === shapeTypes.text && mode === modes.photoPrint) {
              return null;
            }
            return shape;
          }
          if (find(newLayout, { originalAperture: shape.originalAperture })) {
            return remove(newLayout, ({ originalAperture }) => isEqual(originalAperture, shape.originalAperture))[0];
          }
          return null;
        })
        .filter(Boolean);
      newPage.shapes = [...newLayout, ...newPage.shapes];
    } else {
      newPage.shapes = [...newPage.shapes.map(({ originalAperture, ...shape }) => shape), ...newLayout];
    }
    newPage.shapes = SceneHelper.recalculateShapesOnPage({ scene }, page.id);
    return newPage;
  }

  updateBorderType(scene, { borderType }) {
    let newScene = cloneDeep(scene);
    newScene.config.borderType = borderType;
    newScene = this.changeAllPages(newScene, (page, scene) => {
      return this.recalculateLayoutInPage(page, scene, true);
    });
    return newScene;
  }

  toggleBlackAndWhite(scene, { blackAndWhite }) {
    let newScene = cloneDeep(scene);
    newScene.config.blackAndWhite = blackAndWhite;
    newScene = this.changeAllPages(newScene, (page) => {
      return this.changeAllShapesInPage(page, (shape) => {
        if (shape.image) {
          return { ...shape, image: { ...shape.image, saturation: newScene.config.blackAndWhite ? -100 : 0 } };
        }
        return shape;
      });
    });
    return newScene;
  }

  changeLayoutGutterType(scene, payload) {
    const mode = SceneHelper.getMode({ scene });
    if (mode === modes.photoBook) {
      const newScene = cloneDeep(scene);
      newScene.config.layoutGutterType = payload.layoutGutterType;
      return this.changeAllPages(newScene, (page, scene) => {
        const newPage = page;
        newPage.config.layoutGutterType = payload.layoutGutterType;
        return this.recalculateLayoutInPage(page, scene, true);
      });
      // return newScene;
    }
    const newScene = this.setCurrentPageConfig(scene, { layoutGutterType: payload.layoutGutterType });
    return this.changeCurrentPage(newScene, (page, scene) => {
      const newPage = page;
      newPage.shapes = SceneHelper.recalculateShapesOnPage({ scene }, page.id);
      return newPage;
    });
  }

  // changeCoverOption(scene, payload) {
  //   const newScene = cloneDeep(scene);
  //   const { coverOption, sizeOptions } = payload;
  //   newScene.config.coverOption = coverOption;
  //   const dimension = SceneHelper.getDimensionsSync({ scene });
  //   const isSizeSquared = dimension.width === dimension.height;
  //   if (coverOption === coverTypes.SQUARE_HARD_COVER || coverOption === coverTypes.SQUARE_SOFT_COVER) {
  //     if (!isSizeSquared) {
  //       const product = find(sizeOptions, (size) => size.value.dimensions.width === size.value.dimensions.height);
  //       if (product) return this.updateProduct(newScene, { product });
  //     }
  //   } else if (isSizeSquared) {
  //     const product = find(sizeOptions, (size) => size.value.dimensions.width !== size.value.dimensions.height);
  //     if (product) return this.updateProduct(newScene, { product });
  //   }
  //   return newScene;
  // }

  changeLayoutId(scene, { id: layoutId, withFrame = true, pageId }) {
    let newStore = scene;
    if (pageId) {
      scene.config.currentPage = pageId;
    }
    newStore = this.changeCurrentPage(newStore, (page) => {
      const newPage = page;
      newPage.config.layoutId = layoutId;
      newPage.config.designId = '';
      newPage.config.pageMode = pageMods.layout;
      return this.recalculateLayoutInPage(page, scene, true, withFrame);
    });
    newStore = this.setIsLayoutChanged(newStore, false);
    return newStore;
  }

  recalculatePages(scene) {
    this.debug && console.group('recalculatePages');
    const newScene = scene;
    const { pages } = newScene;
    newScene.pages = pages.map((page) => ({
      ...page,
      shapes: SceneHelper.recalculateShapesOnPage({ scene }, page.id),
    }));
    this.debug && console.groupEnd();
    return newScene;
  }

  goToNextPage(scene) {
    const newScene = scene;
    const newPage = SceneHelper.getNextPage({ scene: newScene });
    if (newPage) {
      return this.updatePageId(newScene, { id: newPage.id });
    }
    return newScene;
  }

  onClickNextPage(scene) {
    const newPage = SceneHelper.getNextPage({ scene });
    if (newPage) {
      return this.goToNextPage(scene);
    }
    const numberOfPages = SceneHelper.getCountPages({ scene });
    const printLimitMax = SceneHelper.getPrintLimitMax({ scene });
    if (printLimitMax > numberOfPages) {
      const countPagesInOneLetter = SceneHelper.getCountPagesInOneLetter({ scene });
      const countPages = countPagesInOneLetter - ((numberOfPages - 1) % countPagesInOneLetter) || countPagesInOneLetter;
      return this.addNewPageToPhotoBook(scene, {
        countPages,
      });
    }

    return scene;
  }

  goToPreviousPage(scene) {
    const newScene = scene;
    const newPage = SceneHelper.getPreviousPage({ scene: newScene });
    if (newPage) {
      return this.updatePageId(newScene, { id: newPage.id });
    }
    return newScene;
  }

  sortPage(scene, payload) {
    function arrayMove(arr, oldIndex, newIndex) {
      if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
      return arr; // for testing
    }
    const newScene = cloneDeep(scene);
    const { pageId, newPosition } = payload;
    const { pages } = newScene;
    const currentPagePosition = findIndex(pages, { id: pageId });
    if (currentPagePosition !== -1) {
      newScene.pages = arrayMove(pages, currentPagePosition, newPosition);
    }
    return newScene;
  }

  updateProduct(scene, payload) {
    this.debug && console.group('updateProduct');
    const {
      value: { pages, dimensions, ...value },
      id,
    } = cloneDeep(payload.product);
    this.debug && console.log('pages:', pages);
    const mode = SceneHelper.getMode({ scene });
    let newScene = cloneDeep(scene);
    const currentBackground = newScene.config?.currentBackground;
    newScene = merge(scene, { config: { ...value, productId: id, currentPage: null } });
    newScene.config.currentBackground = currentBackground;
    if (mode !== modes.canvas && mode !== modes.photoPrint && mode !== modes.poster && mode !== modes.photoBook) {
      // newScene.pages = cloneDeep(pages) || [this.getDefaultPage()];
      newScene = merge(newScene, { config: { dimensions } });
    } else {
      newScene = this.recalculateDimensions(newScene, dimensions);
    }
    newScene = this.recalculatePages(newScene);
    this.debug && console.groupEnd();
    if (mode === modes.calendar) {
      return this.setStartCalendar(newScene);
    }
    return newScene;
  }

  /**
   * get range months for calendar mode
   * @param {SceneObject} scene scene object
   * @param {{ day: number, year: number }} [_startFrom] from which date the range should start
   * @return {[Date]} range dates
   */
  getRangeCalendarFrom(scene, _startFrom) {
    const startFrom =
      _startFrom || SceneHelper.getStartFrom({ scene }) || CalculationShapeHelper.getRangeCalendarFrom()[0];
    const admin = SceneHelper.getAdmin({ scene });
    if (admin) startFrom.month = 8;
    return CalculationShapeHelper.getRangeCalendarFrom(new Date(startFrom.year, startFrom.month), admin ? 0 : 11);
  }

  setStartCalendar(scene, payload = {}) {
    this.debug && console.group('setStartCalendar');
    this.debug && console.log('payload:', payload);
    let { weekStarting } = payload;
    const { startFrom, reset } = payload;
    if (!weekStarting) weekStarting = SceneHelper.getWeekStarting({ scene }) || weekStartingConst.monday;
    let newScene = merge(scene, { config: { weekStarting, startFrom } });
    const oldPages = reset ? [] : newScene.pages;
    const newPages = [];
    newPages.push(
      find(oldPages, { id: 'cover' }) ||
        this.getDefaultPage({ id: 'cover', label: 'Cover', pageMode: pageMods.design }),
    );
    const rangeCalendarFrom = this.getRangeCalendarFrom(scene, startFrom);
    rangeCalendarFrom.forEach((date) => {
      const dimensions = SceneHelper.getDimensionsSync({ scene });
      const currentPage =
        find(oldPages, { id: `${date.month}` }) ||
        this.getDefaultPage({
          id: `${date.month}`,
          type: 'calendar-page',
          date,
          label: CalculationShapeHelper.formatDate(date),
          options: { width: dimensions.width, height: dimensions.height },
        });
      currentPage.date = date;
      newPages.push(currentPage);
    });
    newScene.pages = newPages;
    newScene = this.recalculatePages(newScene);
    newScene = this.updatePageLabels(newScene);
    newScene = this.updateRenderText(newScene);
    newScene = this.addTextArrToTextObjects(newScene);
    this.debug && console.log('new pages:', newScene.pages);
    this.debug && console.groupEnd();
    return newScene;
  }

  /**
   *
   * @param {SceneState} scene
   * @param {DimensionsType} _dimensions
   * @param {string} orientation
   * @param {string} [pageId]
   */
  recalculateDimensions(scene, _dimensions = {}, orientation, pageId) {
    const oldScene = cloneDeep(scene);
    const changeOrientation = (newScene) => {
      if (pageId && orientation) {
        return this.changePageByPageId(newScene, pageId, (page) => {
          page.config.orientation = orientation;
          return page;
        });
      }
      if (orientation) {
        const pages = SceneHelper.getAllPages({ scene });
        if (pages.length === 1)
          newScene = this.changeAllPages(newScene, (page) => {
            page.config.orientation = null;
            return page;
          });
      }
      return merge(newScene, { config: { dimensions: _dimensions, ...(orientation ? { orientation } : {}) } });
    };
    let newScene = cloneDeep(scene);
    newScene = changeOrientation(newScene);

    newScene = this.changeAllShapes(oldScene, (shape, page, oldScene) => {
      const oldDimensions = SceneHelper.getDimensionsSync({ scene: oldScene }, page.id);
      const newDimensions = SceneHelper.getDimensionsSync({ scene: newScene }, page.id);

      const valueDeviationFrame = newDimensions.frame - oldDimensions.frame;
      const valueDeviationWidth = newDimensions.width / oldDimensions.width;
      const valueDeviationHeight = newDimensions.height / oldDimensions.height;
      const getNewXAndY = (point) => ({
        x: (point.x - newDimensions.frame) * valueDeviationWidth + newDimensions.frame,
        y: (point.y - newDimensions.frame) * valueDeviationHeight + newDimensions.frame,
      });
      if (SceneHelper.isBackground(shape)) {
        return initImage(shape, newScene.config);
      }
      if (SceneHelper.isShapeFullScreen(shape, page.id, { scene: oldScene })) {
        if (!SceneHelper.isDropZone(shape)) return shape;
        return initImage(
          {
            ...shape,
            x: 0,
            y: 0,
            width: newDimensions.width + 2 * newDimensions.frame,
            height: newDimensions.height + 2 * newDimensions.frame,
          },
          newScene.config,
        );
      }
      if (!SceneHelper.isDropZone(shape)) return shape;
      // recalculate frame (x and y values)
      let newShape = {
        ...shape,
        x: shape.x + valueDeviationFrame,
        y: shape.y + valueDeviationFrame,
      };
      if (valueDeviationWidth !== 1 || valueDeviationHeight !== 1) {
        const newPoint = getNewXAndY(newShape);
        newShape = {
          ...newShape,
          ...newPoint,
          width: newShape.width * valueDeviationWidth,
          height: newShape.height * valueDeviationHeight,
        };

        newShape = initImage(newShape, newScene);
      }
      return newShape;
    });

    newScene = changeOrientation(newScene);

    const mode = SceneHelper.getMode({ scene });
    if (mode === modes.photoPrint || mode === modes.photoBook) {
      newScene = this.changePageByPageId(newScene, pageId, (page) => {
        return this.recalculateLayoutInPage(page, newScene, true);
      });
    }
    return newScene;
  }

  /**
   * @param {SceneState} _scene
   * @param {Object} param1
   * @param {number} param1.frame
   * @returns {SceneState}
   */
  setFrame(_scene, { frame }) {
    return this.recalculateDimensions(_scene, { frame });
  }

  /**
   * @param {SceneState} scene
   * @param {Object} param1
   * @param {string} param1.edges
   * @returns {SceneState}
   */
  setEdges(scene, { edges }) {
    let newStore = scene;
    newStore = this.changeCurrentPage(newStore, (page) => {
      return this.recalculateLayoutInPage(page, scene, true, edges === EDGE_WRAP);
    });
    return newStore;
  }

  /**
   * @param {SceneState} _scene
   * @param {Object} param1
   * @param {number} param1.orientation
   * @returns {SceneState}
   */
  setOrientation(_scene, { orientation }) {
    return this.recalculateDimensions(_scene, {}, orientation);
  }

  toggleOrientation(_scene, { pageId }) {
    const scene = cloneDeep(_scene);
    const orientation = SceneHelper.getOrientationByPageId({ scene }, pageId);
    if (!orientation) return _scene;

    return this.recalculateDimensions(
      _scene,
      {},
      orientation === orientations.landscape ? orientations.portrait : orientations.landscape,
      pageId,
    );
  }

  /**
   * @param {SceneState} scene
   * @param {Object} param1
   * @param {Object} param1.image
   * @returns {SceneState}
   */
  onCompleteUploadImage(scene, { image }) {
    const mode = SceneHelper.getMode({ scene });
    const viewAllPages = SceneHelper.getViewAllPages({ scene });
    let newScene = scene;
    const allShapes = SceneHelper.getAllShapes({ scene });
    const oldShapes = allShapes.filter((shape) => get(shape, 'image.id') === image.oldId);
    if (oldShapes?.length) {
      for (const oldShape of oldShapes) {
        if (oldShape?.id) {
          newScene = this.updateIsEditing(newScene, { isEditing: oldShape.id });
          newScene = this.clickOnImage(newScene, { image });
          if (mode === modes.photoPrint || mode === modes.photoBook) {
            newScene = this.recalculateDimensions(
              newScene,
              {},
              image.width > image.height ? orientations.landscape : orientations.portrait,
              oldShape.pageId,
            );
          }
        }
      }
    } else if ((mode === modes.photoPrint || mode === modes.photoBook) && viewAllPages) {
      newScene = this.clickOnImage(newScene, { image });
    }
    newScene = this.updateViewAllPages(newScene, { viewAllPages });
    return newScene;
  }

  /**
   * @param {SceneState} scene
   * @param {Object} param1
   * @param {Object} param1.image
   * @returns {SceneState}
   */
  updatePreviewImages(scene, { images }) {
    let newScene = scene;
    const allShapes = SceneHelper.getAllShapes({ scene });
    const viewAllPages = SceneHelper.getViewAllPages({ scene });

    for (const image of images) {
      const oldShapes = allShapes.filter((shape) => get(shape, 'image.id') === image.id);

      if (oldShapes?.length) {
        for (const oldShape of oldShapes) {
          if (oldShape?.id) {
            newScene = this.updateIsEditing(newScene, { isEditing: oldShape.id });
            newScene = this.clickOnImage(newScene, { image: { ...oldShape.image, ...image } });
            newScene = this.recalculateDimensions(
              newScene,
              {},
              image.width > image.height ? orientations.landscape : orientations.portrait,
              oldShape.pageId,
            );
          }
        }
      }
    }
    newScene = this.updateViewAllPages(newScene, { viewAllPages });
    return newScene;
  }

  /**
   * @param {SceneState} _scene
   * @param {Object} param1
   * @param {string} param1.pageId
   * @param {number} param1.quality
   * @returns {SceneState}
   */
  changeQuantity(_scene, { pageId, increment }) {
    if (!pageId || !increment) return _scene;
    const scene = cloneDeep(_scene);
    const printLimitMax = SceneHelper.getPrintLimitMax({ scene });
    const photoCount = SceneHelper.getCountPages({ scene });
    if (photoCount + increment > printLimitMax) {
      return _scene;
    }
    return this.changePageByPageId(scene, pageId, (page) => {
      page.quantity = max([1, (page.quantity || 1) + increment]);
      return page;
    });
  }

  onDragEndImage(scene, { image, id }) {
    const mode = SceneHelper.getMode({ scene });
    const viewAllPages = SceneHelper.getViewAllPages({ scene });
    if (mode === modes.photoPrint && viewAllPages) {
      return this.clickOnImage(scene, { image });
    }
    return this.setImage(scene, { image, id });
  }

  uploadPhotos(scene, { images }) {
    if (!images?.length) return scene;
    let newScene = cloneDeep(scene);
    for (const image of images) {
      newScene = this.clickOnImage(newScene, { image });
    }
    return newScene;
  }

  addNewPageToPhotoBook(_scene, { countPages = 1 } = {}) {
    const mode = SceneHelper.getMode({ scene: _scene });
    if (mode !== modes.photoBook) {
      return _scene; // Early return for non-photoBook modes
    }

    let scene = cloneDeep(_scene);

    // Initialize label and orientation for the photobook mode
    let label = scene.pages.length === 0 ? 'cover' : undefined;
    const orientation = orientations.landscape;
    const layoutGutterType = SceneHelper.getLayoutGutterType({ scene });

    // Create pages array with at least one page
    const pages = Array.from({ length: countPages || 1 }, () => {
      const newPage = this.getDefaultPage({ label, layoutGutterType });
      newPage.config.orientation = orientation;
      label = undefined; // only first page if needed must be
      return newPage;
    });

    // Update scene with new pages and configuration
    scene.pages = [...scene.pages, ...pages];
    pages.forEach((page) => {
      scene = this.changeLayoutId(scene, { id: 'default', pageId: page.id });
    });
    scene.config.currentPage = pages[0].id; // Set the current page to the first new page's ID
    scene.config.isEditing = null;
    return scene;
  }

  /**
   * @param {SceneState} _scene
   * @param {{ image: Object }} param1
   * @returns {SceneState}
   */
  removeImageFromAllShapes(scene, { image }) {
    const pageIds = [];
    scene = this.changeAllShapes(scene, (shape, page) => {
      if (shape.image?.id === image.id) {
        pageIds.push(page.id);
        const { image, ...currentShape } = shape;
        return { ...currentShape, image: null };
      }
      return shape;
    });

    pageIds.forEach((pageId) => {
      const pageLayoutSize = SceneHelper.getLayoutSizeByPageId({ scene }, pageId);
      if (pageLayoutSize > 1) {
        const newLayoutId = CalculationShapeHelper.getNewLayoutIdBySize(pageLayoutSize - 1);
        scene = this.changeLayoutId(scene, { id: newLayoutId, pageId, withFrame: false });
      }
    });

    return scene;
  }

  /**
   * @param {SceneState} _scene
   * @param {{ image: Object }} param1
   * @returns {SceneState}
   */
  clickOnImage(_scene, { image }) {
    const getImageOrientation = (mode, image) => {
      if (mode === modes.photoPrint) {
        return image.width > image.height ? orientations.landscape : orientations.portrait;
      }
      return orientations.landscape;
    };

    let scene = cloneDeep(_scene);
    const viewAllPages = SceneHelper.getViewAllPages({ scene });
    const mode = SceneHelper.getMode({ scene });
    if (viewAllPages && (mode === modes.photoPrint || mode === modes.photoBook)) {
      if (mode === modes.photoBook) {
        const findPageNumberForImage = (layoutSizes) => {
          let pageNumber = 1;
          if (!includes(layoutSizes, 0)) {
            for (let i = 2; i < layoutSizes.length; i += 1) {
              if (layoutSizes[i] < layoutSizes[i - 1]) pageNumber = i;
            }
          } else {
            pageNumber = findIndex(layoutSizes, (a) => a === 0);
          }
          return pageNumber;
        };

        const layoutsFromState = SceneHelper.getLayouts({ scene });
        const isImageUsed = SceneHelper.getIsImageUsed({ scene }, image.id);
        // if image already added then remove image from scene
        if (isImageUsed) {
          return this.removeImageFromAllShapes(scene, { image });
        }

        const layoutSizes = SceneHelper.getPageLayoutSizes({ scene });
        const pageNumber = findPageNumberForImage(layoutSizes);

        if (layoutSizes[pageNumber] < 4) {
          const newLayoutId = CalculationShapeHelper.getNewLayoutIdBySize(
            layoutSizes[pageNumber] + 1,
            layoutsFromState,
          );
          if (newLayoutId) {
            scene = this.changeLayoutId(scene, {
              id: newLayoutId,
              withFrame: false,
              pageId: scene.pages[pageNumber].id,
            });

            const editingShape = SceneHelper.editedShapeOnCurrentPage({ scene });
            scene = this.setImage(scene, { image, id: editingShape?.id });
            scene.config.isEditing = null;
          }
        }
      } else {
        const orientation = getImageOrientation(mode, image);
        const newPage = this.getDefaultPage();
        newPage.config.orientation = orientation;
        scene.pages = [...scene.pages, newPage];
        scene = this.changeLayoutId(scene, { id: 'default', pageId: newPage.id });
        const editingShape = SceneHelper.editedShapeOnCurrentPage({ scene });
        scene = this.setImage(scene, { image, id: editingShape?.id });
        scene.config.isEditing = null;
      }
    }

    // if edit drop-zones
    const editingShape = SceneHelper.editedShapeOnCurrentPage({ scene });
    if (SceneHelper.isDropZone(editingShape) || SceneHelper.isLayout(editingShape)) {
      return this.setImage(scene, { image, id: editingShape.id });
    }

    // if there are empty drop-zones
    const shapesFromCurrentPage = SceneHelper.getCurrentPageShapes({ scene });
    const emptyImageShapes = shapesFromCurrentPage.filter(
      (shape) => (SceneHelper.isDropZone(shape) || SceneHelper.isLayout(shape)) && !shape.image,
    );
    if (emptyImageShapes.length) {
      return this.setImage(scene, { image, id: emptyImageShapes[0].id });
    }
    return scene;
    // scene = this.addShapeToCurrentPage(scene, shapeTypes.dropZone);
    // editingShape = SceneHelper.editedShapeOnCurrentPage({ scene });
    // return this.setImage(scene, { image, id: editingShape.id });
  }

  setEvents(scene, events) {
    return { ...scene, events };
  }

  setDefaultEvents(scene, events) {
    return this.setEvents(
      scene,
      events.map((event) => ({ ...event, defaultEvent: true, isActive: true })),
    );
  }

  addEvent(scene, event) {
    const events = SceneHelper.getAllEvents({ scene });
    let newScene = { ...scene, events: [...events, { ...event, id: uuid(), defaultEvent: false, isActive: true }] };
    newScene = this.updateRenderText(newScene);
    newScene = this.addTextArrToTextObjects(newScene);
    newScene = cloneDeep(newScene);
    return newScene;
  }

  changeAllEvents(scene, callback) {
    const events = SceneHelper.getAllEvents({ scene });
    let newScene = scene;
    newScene.events = events.map((event) => callback(event, scene));
    newScene = this.updateRenderText(newScene);
    newScene = this.addTextArrToTextObjects(newScene);
    return cloneDeep(newScene);
  }

  changeEventById(scene, id, callback) {
    return this.changeAllEvents(scene, (event) => {
      if (event.id === id) {
        return callback(event, scene);
      }
      return event;
    });
  }

  updateEvent(scene, event) {
    return this.changeEventById(scene, event.id, (_event) => ({ ..._event, ...event }));
  }

  toggleEventActive(scene, payload) {
    return this.changeEventById(scene, payload.id, (event) => ({ ...event, isActive: !event.isActive }));
  }

  toggleIsEditArea(scene) {
    const newScene = cloneDeep(scene);
    const isEditArea = SceneHelper.getIsEditArea({ scene: newScene });
    newScene.config.isEditArea = !isEditArea;
    newScene.config.isCrop = false;
    newScene.config.textEditing = false;
    newScene.config.isEditing = false;
    return newScene;
  }

  toggleDisableLayouts(scene) {
    let newScene = cloneDeep(scene);
    newScene = this.changeCurrentPage(newScene, (page) => {
      const newPage = page;
      newPage.config.disableLayouts = !SceneHelper.getDisableLayouts({ scene: newScene });
      return newPage;
    });
    return newScene;
  }

  toggleDisableEventsTab(scene) {
    let newScene = cloneDeep(scene);
    newScene = this.changeCurrentPage(newScene, (page) => {
      const newPage = page;
      newPage.config.disableEventsTab = !SceneHelper.getDisableEventsTab({ scene: newScene });
      return newPage;
    });
    return newScene;
  }

  toggleHideEventsInGrid(scene) {
    let newScene = cloneDeep(scene);
    newScene = this.changeCurrentPage(newScene, (page) => {
      const newPage = page;
      newPage.config.hideEventsInGrid = !SceneHelper.getHideEventsInGrid({ scene: newScene, pageId: page.id });
      return newPage;
    });
    newScene = this.updateRenderText(newScene);
    newScene = this.addTextArrToTextObjects(newScene);
    return newScene;
  }

  removeEventById(scene, payload) {
    const events = SceneHelper.getAllEvents({ scene });
    let newScene = { ...scene, events: events.filter((event) => event.id !== payload.id) };
    newScene = this.updateRenderText(newScene);
    newScene = this.addTextArrToTextObjects(newScene);
    return newScene;
  }

  initializedFromApi(scene, payload) {
    const {
      config: {
        orientation: orientationFromState,
        maskOrientation,
        productOptionId: productOptionIdFromState,
        layouts: layoutFromScene,
      },
    } = scene;
    const modeFromState = SceneHelper.getMode({ scene });
    const {
      showLayoutGutterSwitch = true,
      doNotEditableLayout = false,
      addFixedFooterText = false,
      disableAutosave = false,
      layoutGutterType = layoutGutterTypes.gutterInOut,
      printLimitMin = 1,
      printLimitMax = 10,
      lamination = 'gloss',
      orientation: orientationFromApi,
      borderType,
      contentOffsetY = 0,
      disableOrientation = false,
      showBackgroundPicker = true,
      boundaryRelativeMaxHeight,
      boundaryRelativeMaxWidth,
      mobileBoundaryRelativeMaxHeight,
      mobileBoundaryRelativeMaxWidth,
      halfBleed = 3,
      safeMarginText = 4,
      safeMargin = 0,
      product,
      countPagesInOneLetter = 4,
      mode: modeFromApi,
      designId: designIdFromState,
      designs = [],
      canvasId,
      URLQuery: {
        productoption: productOptionIdFromQuery,
        mode: modeFromQuery,
        admin: adminFromQuery = false,
        design: designIdFromQuery,
        category,
      },
    } = payload;
    if (canvasId) {
      return scene;
    }
    const categoryId = get(scene, 'config.categoryId') || category;
    const mode = modeFromApi || modeFromState || modeFromQuery || modes.canvas;
    const admin = adminFromQuery;
    const layouts = (!layoutFromScene || mode === modes.photoPrint) && !admin ? fullScreenLayout : layoutFromScene;
    const productOptionId = productOptionIdFromState || productOptionIdFromQuery;
    const orientation = orientationFromState || orientationFromApi || orientations.landscape;
    let newScene = merge({}, scene, {
      config: {
        initialized: true,
        mode,
        admin,
        layouts,
        showLayoutGutterSwitch,
        doNotEditableLayout,
        safeMargin,
        layoutGutterType,
        version: migrations.lastVersion,
        printLimitMin,
        addFixedFooterText,
        printLimitMax,
        disableAutosave,
        contentOffsetY,
        disableOrientation,
        lamination,
        boundaryRelativeMaxHeight,
        countPagesInOneLetter,
        showBackgroundPicker,
        safeMarginText,
        boundaryRelativeMaxWidth,
        productOptionId,
        mobileBoundaryRelativeMaxHeight,
        mobileBoundaryRelativeMaxWidth,
        view3d: mode === modes.mask,
        maskOrientation: maskOrientation || orientation,
        dimensions: { halfBleed: parseInt(halfBleed, 10) },
        borderType: borderType || borderTypes.noBorder,
        orientation,
        categoryId,
      },
    });
    if (admin) {
      newScene = this.addEvent(newScene, { event: 'Fight Procrastination Day', day: 6, month: 8 });
      newScene = this.addEvent(newScene, { event: 'Be Late for Something Day', day: 5, month: 8 });
    }
    if (mode === modes.photoPrint) newScene.pages = [];
    const dimensions = SceneHelper.getDimensionsSync({ scene: newScene });
    if (mode === modes.photoBook) {
      newScene.pages = [
        this.getDefaultPage({ label: 'Cover', options: { width: dimensions.width, height: dimensions.height } }),
      ];
      for (let i = 0; i < printLimitMin; i++) {
        newScene = this.addNewPageToPhotoBook(newScene);
      }
    }
    if (product) newScene = this.updateProduct(newScene, { product });
    const designId = designIdFromState || designIdFromQuery;
    if (designId && !canvasId) {
      const design = find(designs, { id: designId });
      if (design) {
        newScene = this.setDesign(newScene, design);
      }
    } else if (mode === modes.canvas || mode === modes.poster || mode === modes.mask) {
      const layoutId = find(layouts, { id: 'default' })?.id;
      if (layoutId) {
        newScene = this.changeLayoutId(newScene, { id: 'default', withFrame: false });
      }
    }
    return newScene;
  }

  setScene(store, payload) {
    const newStore = merge({}, store, payload);
    newStore.config.currentPage = newStore?.pages?.[0]?.id;
    newStore.config.isCrop = false;
    newStore.config.textEditing = false;
    newStore.config.isEditing = false;

    return newStore;
  }
}

export default new StoreSceneHelper({ debug: false });
