import {
  get,
  find,
  uniq,
  findLast,
  map,
  filter,
  first,
  min,
  sortBy,
  flattenDeep,
  includes,
  flatten,
  merge,
  groupBy,
  last,
  cloneDeep,
  pick,
  findIndex,
  sumBy,
  indexOf,
} from 'lodash';
import {
  defaultPageCoordinates,
  layoutGutterTypes,
  modes,
  orientations,
  pageMods,
  shapeTypes,
  weekStartingConst,
  eventSettings,
  borderTypes,
} from 'constants/index';
import { getCalendarSettings } from 'constants/calendarSettings';
import { getQuality } from 'utils/utils';
import config from 'config';
import { v4 as uuid } from 'uuid';
import { checkIsDraw, getCorners } from 'components/editors/NewEditor/components/Transformers/utils';
import { EDGE_NOWRAP, EDGE_WRAP } from 'constants/editor';
import defaultTextProps from 'constants/defaultTextProps';
import CalculationShapeHelper from './CalculationShapeHelper';

class SceneHelper {
  constructor({ debug = false }) {
    this.debug = debug;
  }

  // isFullScreen({ scene: { config } }) {
  //   const { layoutId, layouts, layoutGutterType, mode, borderType } = config;
  //   const layout = get(find(layouts, { id: layoutId }), shapeTypes.layout, []);
  //   let isFullScreen = !(layoutGutterType === layoutGutterTypes.gutterInOut) || layout.length === 1;
  //   if (mode === modes.photoPrint) {
  //     isFullScreen = borderType === borderTypes.noBorder;
  //   }
  //   return isFullScreen;
  // }

  /**
   * returns height and width dimensions with "bleeds" and frame if needed
   * @param {import('actions/scene.types').StoreState} storeState - store
   * @param {string} [pageId] - optional parameter pageId, if not pageId - the ID of the current page
   * @returns {import('actions/scene.types').DimensionsType} dimensions
   */
  getDimensionsSync(storeState, pageId) {
    const {
      scene: {
        config: { dimensions: _dimensions, mode },
      },
    } = storeState;
    const page = pageId ? this.getPageById(storeState, pageId) : this.getCurrentPage(storeState);
    const dimensions = merge({}, _dimensions, get(page, 'config.dimensions'));
    const newDimensions = { ...dimensions };
    newDimensions.originalFrame = mode === modes.canvas ? dimensions.frame : 0;
    newDimensions.frame = newDimensions.originalFrame && mode === modes.canvas ? dimensions.frame : 0;
    const orientation = this.getOrientationByPageId(storeState, pageId);
    if (orientation === orientations.portrait) {
      [newDimensions.width, newDimensions.height] = [newDimensions.height, newDimensions.width];
    }
    newDimensions.originalWidth = newDimensions.width;
    newDimensions.originalHeight = newDimensions.height;
    if (includes([modes.calendar, modes.canvas, modes.mask, modes.poster, modes.photoPrint, modes.photoBook], mode)) {
      newDimensions.width += dimensions.halfBleed * 2;
      newDimensions.height += dimensions.halfBleed * 2;
    }
    return newDimensions;
  }

  getSizes(storeState) {
    const { sizes } = storeState.config;
    // const mode = this.getMode(storeState);
    // if (mode === modes.photoBook) {
    //   const coverOption = this.getCoverOption(storeState);
    //   if (coverOption === coverTypes.SQUARE_HARD_COVER || coverOption === coverTypes.SQUARE_SOFT_COVER) {
    //     return sizes.filter((size) => size.value.dimensions.width === size.value.dimensions.height);
    //   }
    //   return sizes.filter((size) => size.value.dimensions.width !== size.value.dimensions.height);
    // }
    return sizes;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @param {String} pageId
   * @returns {{width: number, height: number}}
   */
  getSizeSVG(storeState, pageId) {
    const dimensions = this.getDimensionsSync(storeState, pageId);
    return {
      width: dimensions.width + 2 * dimensions.originalFrame,
      height: dimensions.height + 2 * dimensions.originalFrame,
    };
  }

  /**
   * returns allow area
   * @param {import('actions/scene.types').StoreState} storeState - store
   * @param {String} [_pageId] - optional parameter pageId, if not pageId - the ID of the current page
   * @param {Boolean} withFrame - optional parameter withFrame added frame to allow area
   * @returns {{x: number, y: number, width: number, height:number}} - allow area
   */
  getAllowArea(storeState, _pageId, withFrame = true) {
    const pageId = _pageId || this.getCurrentPage(storeState)?.id;
    const safeMarginFromConfig = this.getSafeMargin(storeState);
    const dimensions = this.getDimensionsSync(storeState, pageId);
    return {
      x: safeMarginFromConfig + (withFrame ? 0 : dimensions.frame),
      y: safeMarginFromConfig + (withFrame ? 0 : dimensions.frame),
      width: dimensions.width + 2 * (withFrame ? dimensions.frame : 0) - 2 * safeMarginFromConfig,
      height: dimensions.height + 2 * (withFrame ? dimensions.frame : 0) - 2 * safeMarginFromConfig,
    };
  }

  /**
   * @param {import('actions/scene.types').Shape} shape
   * @returns {boolean}
   */
  isText(shape) {
    return shape?.type === shapeTypes.text;
  }

  /**
   * @param {import('actions/scene.types').Shape} shape
   * @returns {boolean}
   */
  isLayout(shape) {
    return shape?.type === shapeTypes.layout;
  }

  /**
   * @param {import('actions/scene.types').Shape} shape
   * @returns {boolean}
   */
  isDropZone(shape) {
    return shape?.type === shapeTypes.dropZone;
  }

  /**
   * @param {import('actions/scene.types').Shape} shape
   * @returns {boolean}
   */
  isClipart(shape) {
    return shape?.type === shapeTypes.clipart;
  }

  /**
   * @param {import('actions/scene.types').Shape} shape
   * @returns {boolean}
   */
  isBackground(shape) {
    return shape?.type === shapeTypes.background;
  }

  /**
   * @param {import('actions/scene.types').Shape} shape
   * @returns {boolean}
   */
  isCalendar(shape) {
    return shape?.type === shapeTypes.calendar;
  }

  /**
   * @param {import('actions/scene.types').Shape} shape
   * @returns {boolean}
   */
  isEmptyShape = (shape) => {
    if (this.isText(shape)) return !shape.text;
    // implement other shape types
    return true;
  };

  shapesLayers = {
    [shapeTypes.background]: 0,
    [shapeTypes.clipart]: 1,
    [shapeTypes.dropZone]: 1,
    [shapeTypes.layout]: 1,
    [shapeTypes.text]: 1,
    [shapeTypes.calendar]: 2,
  };

  /**
   * @param {import('actions/scene.types').Shape[]} shapes
   * @returns {import('actions/scene.types').Shape[]}
   */
  sortShapes = (shapes) => sortBy(shapes, (shape) => this.shapesLayers[shape.type] ?? 1);

  /**
   * position the shape in the center of the dimension
   * @param {import('actions/scene.types').Shape} shape - the shape to be placed (only height and width values are used, other values are ignored)
   * @param {import('actions/scene.types').StoreState} storeState
   * @return {import('./CalculationShapeHelper').Point} x and y of the new shape
   */
  shapeToCenter(shape, storeState, pageId) {
    const dimensions = this.getAllowArea(storeState, pageId);
    return CalculationShapeHelper.shapeToCenter(shape, { ...dimensions, x: 0, y: 0 });
  }

  /**
   * check the size of the shape as a percentage
   * @param {import('actions/scene.types').Shape} shape
   */
  isShapePercentage(shape) {
    return this.isBackground(shape) || this.isLayout(shape);
  }

  /**
   * is shape is fullscreen
   * @param {import('actions/scene.types').Shape} shape - the shape
   * @param {String} pageId - page id
   * @param {import('actions/scene.types').StoreState} storeState
   * @return {boolean}
   */
  isShapeFullScreen(shape, pageId, storeState) {
    const dimensions = this.getDimensionsSync(storeState, pageId);
    return CalculationShapeHelper.isShapeFullScreen(shape, dimensions);
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {boolean}
   */
  showSizeTab(storeState) {
    const mode = this.getMode(storeState);
    const viewAllPages = this.getViewAllPages(storeState);
    const size = get(storeState, 'config.sizes') || [];
    if (mode === modes.photoBook) {
      return !viewAllPages;
    }
    if (mode !== modes.photoPrint) {
      return size.length > 1 && !viewAllPages;
    }
    return viewAllPages;
  }

  transformShapeFromPercent({ shape = {}, storeState, customArea, pageId }) {
    if (!this.isShapePercentage(shape)) return shape;
    const area = customArea || this.getAllowArea(storeState, pageId);
    return CalculationShapeHelper.transformShapeFromPercent(shape, area);
  }

  getClippingMask(storeState, pageId) {
    const {
      scene: {
        config: { view3d, isEditing },
      },
    } = storeState;
    const page = this.getPageById(storeState, pageId);
    const clippingMask3dUrl =
      get(page, 'config.clippingMask3dUrl') || get(storeState, 'scene.config.clippingMask3dUrl');
    const clippingMaskUrl = get(page, 'config.clippingMaskUrl') || get(storeState, 'scene.config.clippingMaskUrl');
    return view3d && !isEditing ? clippingMask3dUrl : clippingMaskUrl;
  }

  getOrientationByPageId(storeState, pageId) {
    const page = pageId ? this.getPageById(storeState, pageId) : {};
    const orientation = get(page, 'config.orientation') || get(storeState, 'scene.config.orientation');
    return orientation;
  }

  getMaskOrientationByPageId(storeState, pageId) {
    const page = this.getPageById(storeState, pageId);
    const orientation = get(page, 'config.maskOrientation') || get(storeState, 'scene.config.maskOrientation');
    return orientation;
  }

  getClippingMaskUrlByPageId(storeState, pageId) {
    const page = this.getPageById(storeState, pageId);
    const orientation = get(page, 'config.clippingMaskUrl') || get(storeState, 'scene.config.clippingMaskUrl');
    return orientation;
  }

  getStartFrom(storeState) {
    return get(storeState, 'scene.config.startFrom');
  }

  getWeekStarting(storeState) {
    return get(storeState, 'scene.config.weekStarting');
  }

  getOverlayUrl(storeState, pageId) {
    const {
      scene: {
        config: { overlayUrl: storeOverlayUrl, overlay3dUrl: storeOverlay3dUrl, view3d, isEditing },
      },
    } = storeState;
    const page = this.getPageById(storeState, pageId);
    const overlayUrl = get(page, 'config.overlayUrl') || storeOverlayUrl;
    const overlay3dUrl = get(page, 'config.overlay3dUrl') || storeOverlay3dUrl;

    const mode = this.getMode(storeState);
    if (mode === modes.photoBook) {
      const viewAllPages = this.getViewAllPages(storeState);
      const pageIds = this.getAllPageIds(storeState);
      const pageIndex = indexOf(pageIds, pageId);
      if (viewAllPages && pageIndex === 0) {
        return overlay3dUrl;
      }
      return overlayUrl;
    }
    return view3d && !isEditing ? overlay3dUrl : overlayUrl;
  }

  getScaleOverlay(storeState, pageId) {
    const {
      scene: {
        config: { scaleOverlay: storeScaleOverlay, scaleOverlay3d: storeScaleOverlay3d, view3d, isEditing },
      },
    } = storeState;
    const page = this.getPageById(storeState, pageId);
    const scaleOverlay = get(page, 'config.scaleOverlay') || storeScaleOverlay;
    const scaleOverlay3d = get(page, 'config.scaleOverlay3d') || storeScaleOverlay3d;
    return view3d && !isEditing ? scaleOverlay3d : scaleOverlay;
  }

  getPageCoordinates(storeState, pageId) {
    const page = this.getPageById(storeState, pageId);
    const scaleOverlay =
      get(page, 'config.pageCoordinates') || get(storeState, 'scene.config.pageCoordinates') || defaultPageCoordinates;
    return scaleOverlay;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   */
  getUnderlay(storeState) {
    const {
      scene: {
        config: { underlayUrl },
      },
    } = storeState;
    return underlayUrl;
  }

  getScaleUnderlay(storeState) {
    const {
      scene: {
        config: { scaleUnderlay },
      },
    } = storeState;
    return scaleUnderlay;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {boolean}
   */
  getIsEditArea(storeState) {
    const admin = this.getAdmin(storeState);
    const isEditArea = admin ? get(storeState, 'scene.config.isEditArea') : false;
    return isEditArea;
  }

  getStackFolders(storeState) {
    return storeState?.photos?.foldersHistory || [];
  }

  getCurrentFolder(storeState) {
    const stackFolders = this.getStackFolders(storeState);
    return last(stackFolders) || 0;
  }

  getIsRotateMask(storeState, pageId) {
    const orientation = this.getOrientationByPageId(storeState, pageId);
    const maskOrientation = this.getMaskOrientationByPageId(storeState, pageId);
    return orientation !== maskOrientation;
  }

  shapeById(storeState, id) {
    const shapes = this.getAllShapes(storeState);
    const shape = find(shapes, { id });
    return shape ? this.transformShapeFromPercent({ shape, storeState, pageId: shape.pageId }) : {};
  }

  editedShape(storeState) {
    const isEditing = this.isEditing(storeState);
    return this.shapeById(storeState, isEditing);
  }

  editedShapeOnCurrentPage(storeState, pageId) {
    const isEditing = this.isEditing(storeState);
    return this.getPageShapeById(storeState, isEditing, pageId);
  }

  getEditedTextShape(storeState) {
    const textEditing = this.getTextEditing(storeState);
    if (!textEditing) return false;
    const shape = this.editedShape(storeState);
    if (this.isText(shape)) return shape;
    return false;
  }

  getPlaceholdersObject(storeState, pageId) {
    const object = {
      date: this.getDateByPageId(storeState, pageId),
      eventList: this.getActiveEventListByPageId(storeState, pageId),
      listYears: this.getListYears(storeState),
    };
    return object;
  }

  getEditedTextInInput(storeState, pageId) {
    const admin = this.getAdmin(storeState);
    const editedTextShape = this.getEditedTextShape(storeState);
    if (!admin) {
      if (!editedTextShape) return '';
      const text = CalculationShapeHelper.addPlaceholders(
        editedTextShape.text,
        this.getPlaceholdersObject(storeState, pageId),
      );
      return text || '';
    }
    return editedTextShape?.text || '';
  }

  getCenterEditedText(storeState) {
    const editedText = this.getEditedTextShape(storeState);
    if (!editedText) return null;
    return getCorners({ ...editedText }).center;
  }

  getPhotoById(storeState, id) {
    const {
      photos: { photos, backgrounds, clipart },
    } = storeState;
    const photo = find(photos, { id }) || find(backgrounds, { id }) || find(clipart, { id });
    return photo;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @param {string} id
   * @returns {Object}
   */
  getPhotoByShapeId(storeState, id) {
    const shape = this.shapeById(storeState, id);
    if (!shape?.image?.id) return null;
    const photo = this.getPhotoById(storeState, shape?.image?.id);
    return photo;
  }

  getQualityImageByShapeId(storeState, id) {
    const shape = this.shapeById(storeState, id);
    const photo = this.getPhotoByShapeId(storeState, id);
    return getQuality({ ...photo, ...shape.image }, shape);
  }

  qualityEditedShape(storeState) {
    const shape = this.editedShape(storeState);
    if (!shape?.image) return 0;
    return getQuality(shape.image, shape);
  }

  countLowQualityImage(storeState) {
    const shapes = this.getAllShapes(storeState);
    return shapes.reduce((accumulator, shape) => {
      if (!shape?.image) return accumulator;
      if (this.getQualityImageByShapeId(storeState, shape.id) <= 50) return accumulator + 1;
      return accumulator;
    }, 0);
  }

  countEmptyImages(storeState) {
    const shapes = this.getAllShapes(storeState);
    return shapes.reduce((accumulator, shape) => {
      if (!this.isLayout(shape) && !this.isDropZone(shape)) return accumulator;
      if (!shape.image) return accumulator + 1;
      return accumulator;
    }, 0);
  }

  countNotEmptyImages(storeState) {
    const shapes = this.getAllShapes(storeState);
    return shapes.reduce((accumulator, shape) => {
      if (!this.isLayout(shape) && !this.isDropZone(shape)) return accumulator;
      if (shape.image) return accumulator + 1;
      return accumulator;
    }, 0);
  }

  countPageWithNoImages(storeState) {
    const pages = this.getAllPages(storeState);
    let countPageWithNoImages = 0;
    pages.forEach(({ shapes }) => {
      let countImages = 0;
      shapes.forEach((shape) => {
        if (this.isLayout(shape) || this.isDropZone(shape)) {
          if (shape.image) {
            countImages += 1;
          }
        }
      });
      if (countImages === 0) {
        countPageWithNoImages += 1;
      }
    });
    return countPageWithNoImages;
  }

  getEditorSizePx(storeState, pageId) {
    const {
      scene: {
        config: { scaleUnderlay = 1 },
        state: { sceneWidthPx, sceneHeightPx },
      },
    } = storeState;
    const dimensions = this.getDimensionsSync(storeState, pageId);
    const scale = Math.min(
      sceneWidthPx / scaleUnderlay / (dimensions.width + 2 * dimensions.frame),
      sceneHeightPx / scaleUnderlay / (dimensions.height + 2 * dimensions.frame),
    );
    return [
      dimensions.width * scale,
      dimensions.height * scale,
      dimensions.frame * scale,
      dimensions.halfBleed * scale,
    ]; // TODO: maybe use object
  }

  getFontsByPageId(storeState, id) {
    const page = this.getPageById(storeState, id);
    if (!page?.shapes?.length) return [];
    const fontFamilies = flatten(
      page.shapes.map(({ fontFamily, headerFontFamily }) => [fontFamily, headerFontFamily]),
    ).filter(Boolean);
    return uniq(fontFamilies);
  }

  getDisableLayouts(storeState, pageId) {
    const page = pageId ? this.getPageById(storeState, pageId) : this.getCurrentPage(storeState);
    return get(page, 'config.disableLayouts');
  }

  getDisableEventsTab(storeState, pageId) {
    const page = pageId ? this.getPageById(storeState, pageId) : this.getCurrentPage(storeState);
    return get(page, 'config.disableEventsTab');
  }

  /**
   * @param {import('actions/scene.types').StoreState*} storeState
   * @param {string} pageId
   * @returns {boolean}
   */
  getLoadingByPageId(storeState, pageId) {
    const shapes = this.getShapesByPageId(storeState, pageId);
    const photos = shapes.map((shape) => this.getPhotoByShapeId(storeState, shape.id)).filter(Boolean);
    return photos.reduce((currentLoading, photo) => currentLoading || !photo.uploaded, false);
  }

  /**
   * @param {import('actions/scene.types').StoreState*} storeState
   * @returns {boolean}
   */
  getIsOneImageLoading(storeState) {
    const shapes = this.getAllShapes(storeState);
    const photos = shapes.map((shape) => this.getPhotoByShapeId(storeState, shape.id)).filter(Boolean);
    return photos.reduce((currentLoading, photo) => currentLoading || !photo.uploaded, false);
  }

  getCalendarsByPageId(storeState, pageId) {
    const shapes = this.getShapesByPageId(storeState, pageId);
    return shapes.map((shape) => this.isCalendar(shape)).filter(Boolean);
  }

  isCalendarOnPage(storeState, _pageId) {
    const pageId = _pageId ?? this.currentPageId(storeState);
    const calendars = this.getCalendarsByPageId(storeState, pageId);
    return !!calendars.length;
  }

  getHideEventsInGrid(storeState, pageId) {
    const page = pageId ? this.getPageById(storeState, pageId) : this.getCurrentPage(storeState);
    return get(page, 'config.hideEventsInGrid');
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {import('actions/scene.types').ScenePage[]}
   */
  getAllPages(storeState) {
    const {
      scene: { pages },
    } = storeState;
    return pages || [];
  }

  getAllEvents(storeState) {
    const {
      scene: { events },
    } = storeState;
    return sortBy(events || [], ['month', 'day']);
  }

  getEventsById(storeState, id) {
    const events = this.getAllEvents(storeState);
    return find(events, { id });
  }

  getEventsByMonth(storeState) {
    const events = this.getAllEvents(storeState);
    return map(groupBy(events, 'month'), (value, key) => ({ month: key, events: value }));
  }

  getAllActiveEvents(storeState) {
    const events = this.getAllEvents(storeState);
    return filter(events, { isActive: true });
  }

  getAllShapes(storeState) {
    const pages = this.getAllPages(storeState);
    return flattenDeep(pages.map(({ shapes, id }) => shapes.map((shape) => ({ ...shape, pageId: id }))));
  }

  getAllPageIds(storeState) {
    const pages = this.getAllPages(storeState);
    return pages.map(({ id }) => id);
  }

  getLayoutSizeByPageId(storeState, pageId) {
    const page = this.getPageById(storeState, pageId);
    const layouts = get(storeState, 'scene.config.layouts', [this.getDefaultLayout(storeState)]);
    if (page.shapes.filter((shape) => shape.image)?.length === 0) return 0;
    return find(layouts, { id: page.config.layoutId })?.layout.length || 0;
  }

  getPageLayoutSizes(storeState) {
    const pages = this.getAllPages(storeState);
    const layouts = get(storeState, 'scene.config.layouts', [this.getDefaultLayout(storeState)]);
    const layoutSizes = pages.map((page) => {
      if (page.shapes.filter((shape) => shape.image)?.length === 0) return 0;
      return find(layouts, { id: page.config.layoutId })?.layout.length || 0;
    });
    return layoutSizes;
  }

  getLayouts(storeState) {
    const layouts = get(storeState, 'scene.config.layouts', [this.getDefaultLayout(storeState)]);
    return layouts;
  }

  getAllFonts(storeState) {
    const pages = this.getAllPages(storeState);
    const fontFamilies = flattenDeep(pages.map((page) => this.getFontsByPageId(storeState, page.id))).filter(Boolean);
    return uniq(fontFamilies);
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {string} selected shape id or empty string
   */
  getSelectedShapeId(storeState) {
    const {
      scene: {
        config: { isEditing = '' },
      },
    } = storeState;
    return isEditing;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {boolean}
   */
  isEditing(storeState) {
    const {
      scene: {
        config: { isEditing = false },
      },
    } = storeState;
    return isEditing;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {boolean}
   */
  getViewAllPages(storeState) {
    const viewAllPages = get(storeState, 'scene.config.viewAllPages', null);
    const mode = this.getMode(storeState);
    const defaultViewAllPages = mode === modes.photoPrint || mode === modes.photoBook;
    return viewAllPages ?? defaultViewAllPages;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {boolean}
   */
  getIsSortPages(storeState) {
    const {
      scene: {
        config: { sortPages },
      },
    } = storeState;
    const mode = this.getMode(storeState);
    return mode === modes.photoBook ? !!sortPages : false;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @param {string} pageId
   * @returns {number}
   */
  getQuantityByPageId(storeState, pageId) {
    if (!pageId) return 0;
    const page = this.getPageById(storeState, pageId);
    return get(page, 'quantity', 1);
  }

  getShowSwitchView3d(storeState) {
    const {
      scene: {
        config: { mode, overlay3dUrl, isCrop },
      },
    } = storeState;
    const viewAllPages = this.getViewAllPages(storeState);
    return overlay3dUrl && (mode === modes.mask || mode === modes.calendar) && !isCrop && !viewAllPages;
  }

  getTextEditing(storeState) {
    const {
      scene: {
        config: { textEditing = false },
      },
    } = storeState;
    return textEditing;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {string}
   */
  currentPageId(storeState) {
    const {
      scene: {
        config: { currentPage },
        pages,
      },
    } = storeState;
    return currentPage ?? pages?.[0]?.id;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @param {string} id
   * @returns {import('actions/scene.types').ScenePage}
   */
  getPageById(storeState, id) {
    const pages = this.getAllPages(storeState);
    return find(pages, { id });
  }

  getCurrentPage(storeState) {
    const currentPageId = this.currentPageId(storeState);
    return this.getPageById(storeState, currentPageId);
  }

  getPageDesignId(storeState) {
    const page = this.getCurrentPage(storeState);
    return get(page, 'designId');
  }

  getNextPage(storeState) {
    const currentPageId = this.currentPageId(storeState);
    const pages = this.getAllPages(storeState);
    const indexCurrentPage = findIndex(pages, { id: currentPageId });
    if (indexCurrentPage !== -1 && pages[indexCurrentPage + 1]?.id) {
      return pages[indexCurrentPage + 1];
    }
    return null;
  }

  getPreviousPage(storeState) {
    const currentPageId = this.currentPageId(storeState);
    const pages = this.getAllPages(storeState);
    const indexCurrentPage = findIndex(pages, { id: currentPageId });
    if (indexCurrentPage !== -1 && pages[indexCurrentPage - 1]?.id) {
      return pages[indexCurrentPage - 1];
    }
    return null;
  }

  getShapesByPageId(storeState, id) {
    const page = this.getPageById(storeState, id);
    return get(page, 'shapes') || [];
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {import('actions/scene.types').Shape[]}
   */
  getShapesInSelectedPage(storeState) {
    const pageId = this.currentPageId(storeState);
    const shapes = this.getShapesByPageId(storeState, pageId);
    return shapes;
  }

  getTransformShapesByPageId(storeState, pageId) {
    const shapes = this.getShapesByPageId(storeState, pageId);
    return shapes.map((shape) => this.transformShapeFromPercent({ shape, storeState, pageId }));
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @param {string[]} [filter]
   * @returns
   */
  getCurrentPageShapes(storeState, filter) {
    const currentPageId = this.currentPageId(storeState);
    const shapes = this.getShapesByPageId(storeState, currentPageId).filter((shape) =>
      filter ? filter.includes(shape.type) : !!shape,
    );
    return shapes;
  }

  getDateByPageId(storeState, id) {
    const pageId = id ?? this.currentPageId(storeState);
    const currentPage = this.getPageById(storeState, pageId);
    return get(currentPage, 'date');
  }

  getEventListByDate(storeState, date) {
    const events = this.getAllEvents(storeState);
    return filter(events, { month: date.month });
  }

  getActiveEventListByDate(storeState, date) {
    const events = this.getAllActiveEvents(storeState);
    return filter(events, pick(date, ['month', 'day']));
  }

  getEventsByDate(storeState, date) {
    const formattedDate = CalculationShapeHelper.dateToDayMonthObj(date);
    const events = this.getAllEvents(storeState);
    return filter(events, pick(formattedDate, ['month', 'day']));
  }

  getEventListByPageId(storeState, id) {
    const date = this.getDateByPageId(storeState, id);
    if (!date) return null;
    return this.getEventListByDate(storeState, date);
  }

  getActiveEventListByPageId(storeState, id) {
    const date = this.getDateByPageId(storeState, id);
    if (!date) return null;
    return this.getActiveEventListByDate(storeState, date);
  }

  getAxisOptions(storeState, id) {
    const orientation = this.getOrientationByPageId(storeState, id);
    const config = get(storeState, 'scene.config');
    let { xLabel, yLabel } = config;
    const { axisColor } = config;
    if (orientation !== orientations.landscape) {
      [xLabel, yLabel] = [yLabel, xLabel];
    }
    return { xLabel, yLabel, axisColor };
  }

  getListYears(storeState) {
    const admin = this.getAdmin(storeState);
    const pages = this.getAllPages(storeState);
    const list = uniq(pages.map((page) => page?.date?.year).filter(Boolean)).sort((a, b) => a - b);
    return admin && list?.[0] ? [list[0], list[0] + 1] : list;
  }

  getWeekStartingByPageId(storeState) {
    return storeState?.scene?.config?.weekStarting || weekStartingConst.sunday;
  }

  getCurrentPageShapeIds(storeState) {
    const currentPage = this.getCurrentPage(storeState);
    return currentPage?.shapes?.map(({ id }) => id) || [];
  }

  getPageLabelByPageId(storeState, pageId) {
    if (!pageId) return null;
    const mode = this.getMode(storeState);
    if (mode === modes.photoBook) {
      const pageIds = this.getAllPageIds(storeState);
      if (indexOf(pageIds, pageId) > 0) {
        return `Page ${indexOf(pageIds, pageId)}`;
      }
      if (pageId) {
        return 'Cover';
      }
    }
    const currentPage = this.getPageById(storeState, pageId);
    return get(currentPage, 'label');
  }

  getCurrentPageLabel(storeState) {
    const pageId = this.currentPageId(storeState);
    return this.getPageLabelByPageId(storeState, pageId);
  }

  getShapeIdsByPageId(storeState, id, types = []) {
    const pageId = id ?? this.currentPageId(storeState);
    const currentPage = this.getPageById(storeState, pageId);
    return (
      currentPage?.shapes?.filter((shape) => !types.length || includes(types, shape.type)).map(({ id }) => id) || []
    );
  }

  getSafeMargin(storeState) {
    return parseInt(get(storeState, 'scene.config.safeMargin'), 10) || 0;
  }

  getPageShapeById(storeState, id, pageId) {
    // TODO: then replace shapeById
    const shapes = pageId ? this.getTransformShapesByPageId(storeState, pageId) : this.getAllShapes(storeState);
    const shape = find(shapes, { id });
    if (this.isBackground(shape)) {
      const safeMargin = this.getSafeMargin(storeState);
      return cloneDeep(
        {
          ...shape,
          x: shape.x - safeMargin,
          y: shape.y - safeMargin,
          width: shape.width + 2 * safeMargin,
          height: shape.height + 2 * safeMargin,
          isDraggable: false,
        } || {},
      );
    }
    if (this.isLayout(shape)) return cloneDeep({ ...shape, isDraggable: false } || {});
    if (this.isDropZone(shape)) {
      const corners = getCorners(shape);
      const allowArea = this.getAllowArea(storeState, pageId, false);
      const [isDraggable] = checkIsDraw({
        ...corners,
        allowArea: { ...allowArea, width: allowArea.width + allowArea.x, height: allowArea.height + allowArea.y },
      });
      return cloneDeep({ ...shape, isDraggable } || {});
    }
    return cloneDeep(shape || {});
  }

  getSrcByShapeId(storeState, shapeId, useSrcByShapeId) {
    const photo = this.getPhotoByShapeId(storeState, shapeId);
    return get(photo, useSrcByShapeId ? 'originalSrc' : 'src');
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @return {import('actions/scene.types').LayoutType}
   */
  getDefaultLayout(storeState) {
    const {
      scene: {
        config: { layouts },
      },
    } = storeState;
    return find(layouts, { default: true });
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @return {boolean}
   */
  getDeNotEditableLayout(storeState) {
    const {
      scene: {
        config: { doNotEditableLayout },
      },
    } = storeState;
    return !!doNotEditableLayout;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @param {string} layoutId
   * @param {boolean} [isDefaultLayout]
   * @return {import('actions/scene.types').LayoutType}
   */
  getLayoutByIdOrDefault(storeState, layoutId, isDefaultLayout = true) {
    const {
      scene: {
        config: { layouts },
      },
    } = storeState;
    const defaultLayout = isDefaultLayout ? this.getDefaultLayout(storeState) || first(layouts) || {} : null; // default layout in [layouts of selected size]
    /** @type {import('actions/scene.types').LayoutType} */
    const layoutOption = findLast(layouts, { id: layoutId }) || defaultLayout;
    return layoutOption;
  }

  getLayoutArea(storeState, pageId, transformFromPresent) {
    const page = this.getPageById(storeState, pageId);
    /** all available space, not the one that limits the layout */
    const area = this.getAllowArea(storeState, pageId);
    const layoutArea = cloneDeep(get(page, 'config.layoutArea') || { x: 0, y: 0, width: 1, height: 1 });
    const mode = this.getMode(storeState);
    if (mode === modes.photoBook) {
      const page = this.getPageById(storeState, pageId);
      if (page?.config?.layoutId !== 'default') {
        const dimension = this.getDimensionsSync(storeState, pageId);
        const { width, height, halfBleed } = dimension;
        layoutArea.x += halfBleed / width;
        layoutArea.y += halfBleed / height;
        layoutArea.width -= 2 * (halfBleed / width);
        layoutArea.height -= (2 * halfBleed) / height;
      }
    }
    if (mode === modes.photoPrint) {
      const dimension = this.getDimensionsSync(storeState, pageId);
      const borderType = this.getBorderType(storeState);
      if (borderType === borderTypes.retro) {
        const { retroBorder, retroBottom, width, height, halfBleed } = dimension;
        layoutArea.x += (retroBorder + halfBleed) / width;
        layoutArea.y += (retroBorder + halfBleed) / height;
        layoutArea.width -= 2 * ((retroBorder + halfBleed) / width);
        layoutArea.height -= (retroBottom + halfBleed) / height + (retroBorder + halfBleed) / height;
      }
      if (borderType === borderTypes.border) {
        const { border, width, height, halfBleed } = dimension;
        layoutArea.x += (border + halfBleed) / width;
        layoutArea.y += (border + halfBleed) / height;
        layoutArea.width -= 2 * ((border + halfBleed) / width);
        layoutArea.height -= 2 * ((border + halfBleed) / height);
      }
    }
    return transformFromPresent ? CalculationShapeHelper.transformShapeFromPercent(layoutArea, area) : layoutArea;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @param {string} type
   * @param {string} [pageId]
   * @returns {{x: number; y: number; width: number; height: number}}
   */
  calcAllowAreaSync(storeState, type, pageId) {
    // TODO: need change logic width and height calculations.
    const _safeMarginTest = get(storeState, 'scene.config.safeMarginText');
    const safeMarginFromConfig = this.getSafeMargin(storeState);
    const safeMargin = type === shapeTypes.text ? parseInt(_safeMarginTest, 10) : safeMarginFromConfig;
    const newPageId = pageId || this.currentPageId(storeState);
    const dimensions = this.getDimensionsSync(storeState, newPageId);
    const mode = this.getMode(storeState);
    const borderType = this.getBorderType(storeState);
    if (type === shapeTypes.text && mode === modes.photoPrint && borderType === borderTypes.retro) {
      return {
        x: safeMargin,
        y: dimensions.height - (dimensions.retroBottom + dimensions.retroBorder) + safeMargin,
        width: dimensions.width + dimensions.frame - safeMargin,
        height: dimensions.height + dimensions.frame - safeMargin,
      };
    }
    return {
      x: dimensions.frame + safeMargin,
      y: dimensions.frame + safeMargin,
      width: dimensions.width + dimensions.frame - safeMargin,
      height: dimensions.height + dimensions.frame - safeMargin,
    };
  }

  getLayoutApertures(storeState, pageId) {
    const page = this.getPageById(storeState, pageId);
    const { config: pageConfig } = page;
    const layoutOption = this.getLayoutByIdOrDefault(storeState, pageConfig.layoutId, false);
    const layoutArea = this.getLayoutArea(storeState, pageId);
    if (!layoutOption?.layout) return [];
    let layout = get(layoutOption, shapeTypes.layout);
    layout = layout.map(({ x, y, width, height, type, ...props }) => ({
      x: x / 100,
      y: y / 100,
      width: width / 100,
      height: height / 100,
      originalAperture: { x: x / 100, y: y / 100, width: width / 100, height: height / 100 },
      type: type || shapeTypes.layout,
      ...props,
    }));
    const dimensions = this.getDimensionsSync(storeState, pageId);
    const margin = get(layoutOption, 'margin');
    const marginHorizontal = margin / dimensions.width / 2;
    const marginVertical = margin / dimensions.height / 2;
    const halfMarginHorizontal = marginHorizontal / 2;
    const halfMarginVertical = marginVertical / 2;
    const layoutGutterType = this.getLayoutGutterType(storeState);
    layout = layout?.map((aperture) => {
      let { x, y, width, height } = cloneDeep(aperture);
      if (layoutGutterType === layoutGutterTypes.gutterInOut) {
        const getDivider = (value) => (value > 0 && value < 1 ? 2 : 1);
        width -= marginHorizontal / getDivider(x) + marginHorizontal / getDivider(width + x);
        x += marginHorizontal / getDivider(x);
        height -= marginVertical / getDivider(y) + marginVertical / getDivider(height + y);
        y += marginVertical / getDivider(y);
      }
      if (layoutGutterType === layoutGutterTypes.gutterIn) {
        const getMultiplier = (value) => (value > 0 && value < 1 ? 1 : 0);
        width -= halfMarginHorizontal * getMultiplier(x) + halfMarginHorizontal * getMultiplier(width + x);
        x += halfMarginHorizontal * getMultiplier(x);
        height -= halfMarginVertical * getMultiplier(y) + halfMarginVertical * getMultiplier(height + y);
        y += halfMarginVertical * getMultiplier(y);
      }
      const newAperture = {
        id: uuid(),
        x: x * layoutArea.width + layoutArea.x,
        y: y * layoutArea.height + layoutArea.y,
        width: width * layoutArea.width,
        height: height * layoutArea.height,
        type: shapeTypes.layout,
        originalAperture: aperture.originalAperture,
      };
      return newAperture;
    });
    const allowArea = this.calcAllowAreaSync(storeState, shapeTypes.text, pageId);
    const mode = this.getMode(storeState);
    if (mode === modes.photoPrint) {
      const borderType = this.getBorderType(storeState);
      if (borderType === borderTypes.retro) {
        const text = {
          ...defaultTextProps,
          id: uuid(),
          x: allowArea.x,
          y: allowArea.y,
          width: allowArea.width - allowArea.x,
          fontSize: min([10, allowArea.height - allowArea.y]),
        };
        layout = [...layout, text];
      }
    }
    return layout || [];
  }

  recalculateShapesOnPage(storeState, pageId) {
    const page = this.getPageById(storeState, pageId);
    const { config: pageConfig } = page;
    let { shapes: pageShapes } = page;
    if (pageConfig.pageMode === pageMods.design) {
      pageShapes = filter(pageShapes, (shape) => !this.isLayout(shape));
    }
    if (page.date) {
      if (!find(pageShapes, { type: shapeTypes.calendar })) {
        const id = uuid();
        const dimensions = this.getDimensionsSync(storeState, pageId);
        const halfDimensionsHeight = dimensions.height / 2;
        const shapeWidth = min([dimensions.width, halfDimensionsHeight * (3 / 2)]) * 0.9;
        const calendarType = 1;
        const calendarSettings = getCalendarSettings({ calendarType });
        const calendarRatio = calendarSettings.CalendarShape.height / calendarSettings.CalendarShape.width;
        const shapeHeight = shapeWidth * calendarRatio;
        const { x, y } = CalculationShapeHelper.shapeToCenter(
          { width: shapeWidth, height: shapeHeight },
          { x: 0, y: halfDimensionsHeight, width: dimensions.width, height: halfDimensionsHeight },
        );
        const calendarShape = {
          id,
          x,
          y,
          rotation: 0,
          width: shapeWidth,
          height: shapeHeight,
          type: shapeTypes.calendar,
          image: null,
          backgroundColor: '#66666604',
          mainColor: '#545358',
          dayFontSize: 7,
          headerFontSize: 10,
          secondaryColor: '#d5d5d5',
          dayFormat: 'EEEEEE',
          borderTop: true,
          borderColor: '#545358',
          fontFamily: 'Raleway',
          isBold: true,
          isItalic: false,
          headerFontFamily: 'Raleway',
          headerIsBold: true,
          headerIsItalic: false,
          headerColor: '#545358',
          calendarType,
          styleEvent: {
            fontSize: '6',
            fontFamily: 'Raleway',
            isBold: false,
            isItalic: false,
            fill: '#545358',
          },
        };
        calendarShape.days = [];
        pageShapes = [...pageShapes, calendarShape];
      }
    }
    const newCurrentLayout = [
      ...filter(pageShapes, this.isBackground),
      ...filter(pageShapes, (element) => !this.isBackground(element) && !this.isCalendar(element)),
      ...filter(pageShapes, this.isCalendar),
    ];
    pageShapes = newCurrentLayout;
    return pageShapes;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @return {import('actions/scene.types').ScenePage[]} array of pages
   */
  recalculatePages(storeState) {
    const {
      scene: { pages },
    } = storeState;
    const newPages = pages.map((page) => ({ ...page, shapes: this.recalculateShapesOnPage(storeState, page.id) }));
    return newPages;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @return {(string|undefined)} layout id
   */
  getLayoutId(storeState) {
    const currentPage = this.getCurrentPage(storeState);
    return get(currentPage, 'config.layoutId');
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @return {boolean} layout id
   */
  getIsDefaultLayout(storeState) {
    const layoutId = this.getLayoutId(storeState);
    const defaultLayout = this.getDefaultLayout(storeState);
    return layoutId === defaultLayout.id;
  }

  getLayoutGutterType(storeState) {
    const mode = this.getMode(storeState);
    if (mode === modes.photoBook) {
      return get(storeState, 'scene.config.layoutGutterType');
    }
    const currentPage = this.getCurrentPage(storeState);
    return get(currentPage, 'config.layoutGutterType');
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @return {boolean} layout id
   */
  getCoverOption(storeState) {
    return get(storeState, 'scene.config.coverOption');
  }

  getBackgroundColorByPageId(storeState, id) {
    const {
      scene: {
        config: { currentBackground },
      },
    } = storeState;
    const pageId = id ?? this.currentPageId(storeState);
    const pageBackgroundColor = this.getPageById(storeState, pageId)?.config?.backgroundColor;
    return pageBackgroundColor || currentBackground?.value || 'white';
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {string}
   */
  getMode(storeState) {
    const {
      scene: {
        config: { mode },
      },
    } = storeState;
    return mode;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {string}
   */
  getCountPagesInOneLetter(storeState) {
    const mode = this.getMode(storeState);
    const countPagesInOneLetter = parseInt(get(storeState, 'scene.config.countPagesInOneLetter') || '4', 10);
    return mode === modes.photoBook ? countPagesInOneLetter : 1;
  }

  getEdgeWrap(storeState) {
    const mode = this.getMode(storeState);
    if (mode !== modes.canvas) return EDGE_NOWRAP;
    const fullScreenShapes = this.getAllShapes(storeState).filter((shape) =>
      this.isShapeFullScreen(shape, shape.pageId, storeState),
    );
    if (!fullScreenShapes.length) return EDGE_NOWRAP;
    return EDGE_WRAP;
  }

  getBorderType(storeState) {
    const borderType = get(storeState, 'scene.config.borderType');
    return borderType;
  }

  getAdmin(storeState) {
    const {
      scene: {
        config: { admin },
      },
    } = storeState;
    return admin;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {number}
   */
  getPrintLimitMax(storeState) {
    return get(storeState, 'scene.config.printLimitMax') || 1;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {number}
   */
  getPrintLimitMin(storeState) {
    return get(storeState, 'scene.config.printLimitMin') || 1;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {number}
   */
  getCountPages(storeState) {
    const pages = this.getAllPages(storeState);
    const pageCount = sumBy(pages, (o) => (o && o.id ? o.quantity || 1 : 0)); // FIXME: need to check
    return pageCount;
  }

  getCanvasId(storeState) {
    const {
      app: { canvasId },
    } = storeState;
    return canvasId;
  }

  isShowArrow(storeState) {
    const mode = this.getMode(storeState);
    return mode !== modes.mask && mode !== modes.calendar && mode !== modes.photoPrint && mode !== modes.photoBook;
  }

  getCurrentBackgroundImageId(storeState) {
    const pageId = this.currentPageId(storeState);
    const shapes = this.getShapesByPageId(storeState, pageId);
    const background = find(shapes, { type: shapeTypes.background });
    return background?.image.id;
  }

  getAllImages(storeState) {
    const shapes = this.getAllShapes(storeState);
    const images = shapes.map((shape) => shape?.image).filter(Boolean);
    return images;
  }

  getAllImageIds(storeState) {
    return this.getAllImages(storeState)
      .map((image) => image.id)
      .filter(Boolean);
  }

  getIsImageUsed(storeState, imageId) {
    const usedImageIds = this.getAllImageIds(storeState);
    return usedImageIds.includes(imageId);
  }

  getCountPagesWithoutImages(storeState) {
    const pages = this.getAllPages(storeState);
    const countPagesWithoutImages = pages.filter((page) => {
      const countImages = page.shapes.filter((shape) => {
        if (!this.isLayout(shape) && !this.isDropZone(shape)) return false;
        if (shape.image) return true;
        return false;
      }).length;
      return !countImages;
    }).length;
    return countPagesWithoutImages;
  }

  getIsOneImageLoaded(storeState) {
    const countNotEmptyImages = this.countNotEmptyImages(storeState);
    return !!countNotEmptyImages;
  }

  getActivePhotoId(storeState, pageId = this.currentPageId(storeState)) {
    const shapes = this.getShapesByPageId(storeState, pageId);
    const photoIds = shapes
      .map((shape) => {
        if (this.isLayout(shape) || this.isDropZone(shape)) {
          return shape?.image?.id;
        }
        return null;
      })
      .filter(Boolean);
    return photoIds;
  }

  isDisableAutosave(storeState) {
    const {
      scene: {
        config: { disableAutosave },
      },
    } = storeState;
    return config.disableAutosave || disableAutosave;
  }

  getBackgroundOption(storeState) {
    const {
      scene: {
        config: { backgroundOptions },
      },
    } = storeState;
    return backgroundOptions;
  }

  getCurrentThemTextColor(storeState) {
    const backgroundOptions = this.getBackgroundOption(storeState);
    const backgroundColor = this.getBackgroundColorByPageId(storeState);
    const currentThemTextColor = find(backgroundOptions, { value: backgroundColor })?.textColor;
    return currentThemTextColor;
  }

  /**
   * @param {import('actions/scene.types').StoreState} storeState
   * @returns {boolean}
   */
  getIsLayoutChanged(storeState) {
    const isLayoutChanged = get(storeState, 'scene.config.isLayoutChanged');
    return isLayoutChanged;
  }

  isChangedTextColor(storeState, changeOnAllPages = false) {
    const currentThemTextColor = this.getCurrentThemTextColor(storeState);
    const shapes = changeOnAllPages ? this.getAllShapes(storeState) : this.getCurrentPageShapes(storeState);
    let _isChangedTextColor = false;
    shapes.forEach((shape) => {
      if (this.isText(shape) && shape.fill !== currentThemTextColor) {
        _isChangedTextColor = true;
      }
      if (
        this.isCalendar(shape) &&
        (shape.mainColor !== currentThemTextColor || shape.headerColor !== currentThemTextColor)
      ) {
        _isChangedTextColor = true;
      }
    });
    return _isChangedTextColor;
  }

  isShowOrientationSwitch(storeState) {
    const mode = this.getMode(storeState);
    const {
      scene: {
        config: { disableOrientation },
      },
    } = storeState;
    const showOrientationSwitch = includes([modes.poster, modes.canvas, modes.mask], mode) && !disableOrientation;
    return showOrientationSwitch;
  }

  /**
   * that checks if we can add / edit an event
   * @param {import('actions/scene.types').StoreState} storeState - store
   * @param {Object} param
   * @param {Date} param.date - event date
   * @param {string} param.title - event title
   * @param {string} [param.id] - event id if edit
   * @returns {[boolean, string[]]} - the first argument is whether you can add an event. the second argument is a list of errors
   */
  canAddEvent(storeState, { id, date, title }) {
    const events = this.getEventsByDate(storeState, date).filter((event) => event.id !== id);
    const errors = [];
    if (title.length > eventSettings.maxLengthTittle) {
      errors.push('The title is too big');
    }
    if (events.length >= eventSettings.maxEventPerDay) {
      errors.push(`You cannot add more than ${eventSettings.maxEventPerDay} events in one day`);
    }
    return [!errors.length, errors];
  }
}

export default new SceneHelper({ debug: false });
