import React, { useState, useCallback } from 'react';
import { values, find } from 'lodash';
import Button from 'components/common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';
import { layoutGutterTypes, modes, layoutModes, borderTypes, orientations } from 'constants/index';
import Select from 'react-select';
import MultiOptionButton from 'components/common/MultiOptionButton/MultiOptionButton';
import CalculationShapeHelper from 'utils/CalculationShapeHelper';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { defaultColors } from 'api/colors';
import Modal from './Modal';
import './DevModalButton.scss';
import TestProducts from './TestProducts';

const boolOption = [true, false];

const useLocalApiOptions = [
  {
    value: true,
    label: 'Use Local Api',
  },
  {
    value: false,
    label: 'Use default Api',
  },
];

const apiOptions = [
  {
    key: 'mode',
    options: CalculationShapeHelper.generateOptionsFromConstants(values(modes)),
  },
  {
    key: 'enableMultiLayout',
    options: CalculationShapeHelper.generateOptionsFromConstants(values(boolOption)),
  },
  {
    key: 'addFixedFooterText',
    options: CalculationShapeHelper.generateOptionsFromConstants(values(boolOption)),
  },
  {
    key: 'borderType',
    options: CalculationShapeHelper.generateOptionsFromConstants(values(borderTypes)),
  },
  {
    key: 'layoutMode',
    options: CalculationShapeHelper.generateOptionsFromConstants(values(layoutModes)),
  },
  {
    key: 'orientation',
    options: CalculationShapeHelper.generateOptionsFromConstants(values(orientations)),
  },
  {
    key: 'showBackgroundPicker',
    options: CalculationShapeHelper.generateOptionsFromConstants(values(boolOption)),
  },
  {
    key: 'defaultBackground',
    options: CalculationShapeHelper.generateOptionsFromConstants(defaultColors.map(({ value }) => value)),
  },
  {
    key: 'layoutGutterType',
    options: CalculationShapeHelper.generateOptionsFromConstants(values(layoutGutterTypes)),
  },
  {
    key: 'showLayoutGutterSwitch',
    options: CalculationShapeHelper.generateOptionsFromConstants(values(boolOption)),
  },
  {
    key: 'doNotEditableLayout',
    options: CalculationShapeHelper.generateOptionsFromConstants(values(boolOption)),
  },
  {
    key: 'overlayUrl',
    type: 'text',
  },
  {
    key: 'printLimitMin',
    type: 'int',
  },
  {
    key: 'printLimitMax',
    type: 'int',
  },
  {
    key: 'safeMargin',
    type: 'int',
  },
];

const Input = ({ label, Component, onChange, ...props }) => (
  <div style={{ marginTop: '15px', display: 'flex' }}>
    <div style={{ flex: 2, padding: '9px 0px' }}>{label}:</div>
    <div style={{ flex: 3 }}>
      <Component {...props} />
    </div>
    <div style={{ flex: 1 }} className="icon-button-container" onClick={() => onChange(undefined)}>
      <FontAwesomeIcon.Memo icon={faTrashAlt} className="icon-button" />
    </div>
  </div>
);

const MultiOption = ({ label, options, value, onChange }) => (
  <Input
    label={label}
    onChange={onChange}
    Component={() => (
      <Select options={options} onChange={({ value }) => onChange(value)} value={find(options, { value })} />
    )}
  />
);

const IntOption = ({ label, value, onChange }) => (
  <Input
    label={label}
    Component={() => (
      <input className="number-input" type="number" min="1" onChange={(e) => onChange(e.target.value)} value={value} />
    )}
  />
);

const TextOption = ({ label, value, onChange }) => (
  <Input
    label={label}
    Component={() => (
      <input className="number-input" type="text" onChange={(e) => onChange(e.target.value)} value={value} />
    )}
  />
);

const DevModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [useLocalApi, setUseLocalApi] = useStateWithLocalStorage('useLocalApi', false);
  const [localApi, setLocalApi] = useStateWithLocalStorage('localApi', {});
  const changeLocalApi = useCallback(
    (key) => (value) => {
      const newLocalApi = { ...localApi };
      newLocalApi[key] = value;
      setLocalApi(newLocalApi);
    },
    [localApi, setLocalApi],
  );

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        className="download-button"
        data-for="bottomTooltip"
        data-tip="[DEV] API config"
      >
        <FontAwesomeIcon.Memo icon={faEdit} />
      </Button>
      <Modal isOpen={isOpen} className="dev-modal-button">
        <div className="modal-body">
          <MultiOptionButton options={useLocalApiOptions} onChange={setUseLocalApi} value={useLocalApi} />
          {useLocalApi &&
            apiOptions.map(({ key, options, type }) => {
              if (options) {
                return (
                  <MultiOption
                    key={key}
                    label={key}
                    options={options}
                    value={localApi[key]}
                    onChange={changeLocalApi(key)}
                  />
                );
              }
              if (type === 'int') {
                return <IntOption key={key} label={key} value={localApi[key]} onChange={changeLocalApi(key)} />;
              }
              if (type === 'text') {
                return <TextOption key={key} label={key} value={localApi[key]} onChange={changeLocalApi(key)} />;
              }
              return null;
            })}
          {!useLocalApi ? <TestProducts /> : null}
        </div>
        <div className="action-buttons">
          <Button rounded color="azure" onClick={() => setIsOpen(false)}>
            Close Modal
          </Button>
          <Button rounded color="azure" onClick={() => window.location.reload(false)}>
            Reload
          </Button>
        </div>
      </Modal>
    </>
  );
};
const MemoModal = React.memo(DevModal);

export default MemoModal;
